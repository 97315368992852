import { Component, OnInit, Input, Output, EventEmitter, Optional } from "@angular/core"
import { Location } from "@angular/common"
import { Subscription } from "rxjs"
import { Router, ActivatedRoute } from "@angular/router"

import { Material, Materiales } from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { PdfView, YoutubeVideoView, GameView } from "@puntaje/shared/core"
import { MaterialRelaciones, MaterialRelacion } from "@puntaje/puntaje/api-services"

import { Angulartics2 } from "angulartics2"
import { MaterialPreviewModalService } from "./modals/material_preview_modal.service"

@Component({
    selector: "material-with-thumbnail",
    templateUrl: "material_with_thumbnail.component.html",
    styleUrls: ["material_with_thumbnail.component.scss"]
})
export class MaterialWithThumbnailComponent implements OnInit {
    @Input() tracking: boolean = false
    @Input() material: Material
    materialRelaciones: MaterialRelacion[]
    @Input() enableMaterialRelacionado: boolean = false
    @Input() enableModal: boolean = false
    isVideo: boolean = false
    isArchivo: boolean = false
    isPdf: boolean = false
    isGame: boolean = false
    thumbType: string = "archivo"
    thumbUrl: string
    @Input() id: number

    constructor(
        protected materialesService: Materiales,
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected materialRelacionesService: MaterialRelaciones,
        protected materialPreviewModalService: MaterialPreviewModalService,
        @Optional() public angulartics2: Angulartics2
    ) {}

    ngOnInit() {
        //Material viene incluido como input
        if (this.material) {
            let params = {
                material_relacion: {
                    material_id: this.material.id
                },
                methods: "material"
            }

            if (this.enableMaterialRelacionado) {
                this.materialRelacionesService.where(params).then((response: MaterialRelacion[], total: number) => {
                    this.materialRelaciones = response
                })
            }

            this.isVideo =
                this.material.material_tipo_nombre == "Video" || this.material.material_tipo_nombre == "Streaming"
            this.isArchivo = this.material.material_tipo_nombre == "Archivo"
            this.isPdf = this.material.material_tipo_nombre == "Archivo" && this.material.getExtension() == "pdf"
            this.isGame = this.material.material_tipo_nombre == "Juego"

            switch (this.material.material_tipo_nombre) {
                case "Video":
                case "Streaming":
                    if (this.material.url.indexOf("youtube") != -1 || this.material.url.indexOf("youtu.be") != -1) {
                        this.thumbType = "video-youtube"
                        this.thumbUrl =
                            "https://img.youtube.com/vi/" + this.getYoutubeVideoId(this.material.url) + "/mqdefault.jpg"
                    } else {
                        this.thumbType = "video"
                    }
                    break
                case "Archivo":
                    this.thumbType = this.isPdf ? "pdf" : "archivo"
                    break
                default:
                    this.thumbType = "archivo"
                    break
            }
            if (this.material.thumbnail) this.thumbUrl = this.material.thumbnail
        }
    }

    getYoutubeVideoId(url) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        return match && match[2].length == 11 ? match[2] : false
    }

    goToMaterial() {
        if (this.isVideo) {
            this.trackClickEvent((this.material as any).nombre_asignatura, "Clase")
        } else {
            this.trackClickEvent((this.material as any).nombre_asignatura, this.material.material_tipo_nombre)
        }

        if (this.enableModal) {
            this.materialPreviewModalService.open(this.material)
        } else {
            this.router.navigate(["/materiales/" + this.material.id])
        }
    }

    goToGuia(idGuia) {
        this.router.navigate(["/materiales/" + idGuia])
    }

    goToEvaluacion(idEvaluacion) {
        this.router.navigate(["ensayos/" + idEvaluacion + "/realizar"])
    }

    getDownloadLink = (m: Material) => {
        return m.url
    }

    isFileLink = (m: Material) => {
        return m.isFile()
    }

    trackClickEvent(category: string, label: string): void {
        if (this.tracking && this.angulartics2) {
            this.angulartics2.eventTrack.next({
                action: "visualizar",
                properties: {
                    category: category,
                    label: label
                }
            })
        }
    }

    trackDownload() {
        if (this.tracking && this.angulartics2) {
            this.angulartics2.eventTrack.next({
                action: "descarga",
                properties: {
                    category: (this.material as any).nombre_asignatura,
                    label: this.material.material_tipo_nombre
                }
            })
        }
    }
}
