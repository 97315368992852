import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "app-usuario-registrado",
    templateUrl: "./usuario-registrado.component.html",
    styleUrls: ["./usuario-registrado.component.scss"]
})
export class UsuarioRegistradoComponent implements OnInit {
    unloggedLayoutTitle = "¡Ya casi estás listo!"
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.usuarios.usuario_registrado")
    }
}
