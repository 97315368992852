<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>

<ll-top-contenido>
    <menu-evaluacion-tipo-asignaturas
        [showTitleOverMenu]="showTitleOverMenu"
        [evaluacionTipos]="evaluacionTipos"
    ></menu-evaluacion-tipo-asignaturas>
</ll-top-contenido>

<ll-titulo>
    {{ titulo }}
</ll-titulo>

<div class="row">
    <div class="col-md-8 col-sm-6">
        <plan-clases-alumnos
            *ngIf="generadorInstrumentos && showPlanesCompartidosProfesor"
            [evaluacionTipo]="tipoInstrumento"
            [ignoreNiveles]="true"
            [showMisPlanes]="true"
            [ocultarSinPlanes]="true"
            titulo="{{ tituloSimple }}"
            [generadorInstrumentos]="generadorInstrumentos"
            [msjNoPlanes]="msjNoPlanes"
            [planClaseRoute]="planClaseRoute"
            [showAsignatura]="showAsignatura"
        ></plan-clases-alumnos>
        <plan-clases-alumnos
            *ngIf="generadorInstrumentos && showPlanesRecomendados"
            [evaluacionTipo]="tipoInstrumento"
            [ignoreNiveles]="ignoreNivelesRecomendados"
            [showPlanesPredefinidos]="true"
            titulo="{{ planesDeClase }}"
            [generadorInstrumentos]="generadorInstrumentos"
            [msjNoPlanes]="msjNoPlanes"
            [planClaseRoute]="planClaseRoute"
            [showAsignatura]="showAsignatura"
        ></plan-clases-alumnos>
    </div>
    <div class="col-md-4 col-sm-6">
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
    </div>
</div>
