<nav class="menu-asignaturas" [class.enableResponsive]="enableResponsive">
    <ul>
        <li *ngFor="let a of lista_asignaturas" [class.current-asignatura]="a.id == asignaturaId">
            <a
                [routerLink]="['./']"
                [queryParams]="
                    clasificaciones
                        ? { asignatura_id: a.id, clasificaciones: clasificaciones }
                        : { asignatura_id: a.id }
                "
            >
                <ogr-icon [name]="a.icono" class="icono {{ a.clase }}"></ogr-icon>
                <span class="texto">{{ a.abreviacion }}</span>
            </a>
        </li>
    </ul>
</nav>
