/** @format */

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import { Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { Clasificacion, Clasificaciones, NebuAuthService, ReporteRazones } from "@puntaje/nebulosa/api-services"
import { AuthService, GenericModalComponent, SessionService } from "@puntaje/shared/core"
import {
    Establecimiento,
    Establecimientos,
    Lugar,
    Lugares,
    UsuarioRegistroUtem,
    Usuarios,
    UsuarioRegistroFormUtem
} from "@puntaje/puntaje/api-services"
import { GetStoreConfig, SetCodigoConfirmacionData, State } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"
import { ModalSelectTipoEnvioComponent } from "./modales/modal-select-tipo-envio/modal-select-tipo-envio.component"
import { RegisterStepsService, RegisterStepStatus } from "./register-steps/register-steps.service"
import { BaseRegisterComponent } from "./base-register/base-register.component"

@Component({
    selector: "usuario-registro-utem",
    templateUrl: "register_utem.form.component.html",
    styleUrls: ["register.component.scss"]
})
export class RegisterUtemComponent extends BaseRegisterComponent implements OnInit, OnDestroy {
    usuarioRegistro: UsuarioRegistroUtem = new UsuarioRegistroUtem()
    UsuarioRegistroFormUtem: UsuarioRegistroFormUtem
    // params = UsuarioRegistroForm.formParams;
    params: any = UsuarioRegistroFormUtem.formParams
    form: UntypedFormGroup
    save_button_text = "Guardar"
    saved: boolean = false
    lugar: Lugar
    steps: any[]
    currentStep: number
    subSteps: Subscription
    subIndex: Subscription
    subValidateAndGo: Subscription
    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]
    omitir: boolean = true // true mientras se acomoda lo de los msj
    selectEnvioConfirmacion: boolean = true //true mientras no funcione los msj
    egresado: Clasificacion
    telefono: string
    invalidLogin: boolean
    config: typeof config
    message: string

    filteredEstablecimientos: Establecimiento[]
    openModal: EventEmitter<any> = new EventEmitter<any>()
    @ViewChild(GenericModalComponent) genericModal: GenericModalComponent
    @ViewChild(ModalSelectTipoEnvioComponent)
    modalSelectTipoEnvioComponent: ModalSelectTipoEnvioComponent

    @Output() onTitleChange: EventEmitter<string> = new EventEmitter<string>()
    @Output() onUsuarioRegistrado: EventEmitter<any> = new EventEmitter<any>()

    constructor(
        usuariosService: Usuarios,
        protected clasificacionesService: Clasificaciones,
        router: Router,
        protected injector: Injector,
        establecimientosService: Establecimientos,
        lugaresService: Lugares,
        protected cdr: ChangeDetectorRef,
        protected registerStepsService: RegisterStepsService,
        protected nebuAuthService: NebuAuthService,
        protected store: Store<State>,
        protected authService: AuthService,
        protected sessionService: SessionService,
        protected reporteRazonesService: ReporteRazones
    ) {
        super(usuariosService, router, lugaresService, establecimientosService)
    }

    ngOnInit() {
        this.form = UsuarioRegistroFormUtem.getForm(this.usuarioRegistro, null, this.injector)
        this.steps = this.registerStepsService.steps

        this.currentStep = this.registerStepsService.currentIndex
        this.onTitleChange.next(this.steps[this.currentStep].titulo)
        this.subIndex = this.registerStepsService.currentIndexSubject.subscribe((index: number) => {
            this.currentStep = index
            this.onTitleChange.next(this.steps[this.currentStep].titulo)
        })
        this.subSteps = this.registerStepsService.currentStepsSubject.subscribe((steps: any) => {
            this.steps = steps
        })
        this.subValidateAndGo = this.registerStepsService.validateAndGoToStepSubject.subscribe((index: number) => {
            this.checkStep()
            this.registerStepsService.goToStep(index)
        })

        this.clasificacionesService
            .where({
                clasificacion_tipo: { clasificacion_tipo: "curso" },
                clasificacion: { clasificacion: "Egresado" }
            })
            .then((clasificaciones: Clasificacion[]) => {
                this.egresado = clasificaciones[0]
            })

        this.form.controls.rut.valueChanges.pipe(filter(x => !!x && x.length > 1)).subscribe((value: string) => {
            value = value.replace(/-/g, "")
            const almostLast = value.length - 1

            value = value.substring(0, almostLast) + "-" + value.substring(almostLast)

            this.form.controls.rut.setValue(value, { emitEvent: false })
        })
    }

    validarTipoEnvio(event) {
        if (event.tipo == "sms") {
            this.usuarioRegistro.telefono = event.telefono
            this.save("sms")
        }
        if (event.tipo == "mail") {
            this.save("mail")
        }
    }

    getTipoEnvio() {
        UsuarioRegistroFormUtem.markFormControlsAsTouched(this.form)
        this.checkStep()
        this.usuarioRegistro.lugar = this.lugar
        if (this.form.valid) {
            this.onUsuarioRegistrado.emit()
            this.modalSelectTipoEnvioComponent.open()
        }
    }

    registrarse() {
        if (this.usuarioRegistro.egresado) {
            this.usuarioRegistro.nivel_id = this.egresado.id
        }
        let usuario = this.usuarioRegistro.toUsuario()
        ;(usuario as any).check_from_nomina = 1

        UsuarioRegistroFormUtem.markFormControlsAsTouched(this.form)
        this.checkStep()

        if (this.form.valid) {
            this.usuariosService.enableIgnoreModel()
            this.usuariosService.save(usuario).then(response => {
                this.usuariosService.disableIgnoreModel()
                const verifyFromNomina = false
                return this.loginGeneral(this.authService.loginRegistroOPassword(response), verifyFromNomina)
            })
        }
    }

    save(typoEnvio) {
        if (this.usuarioRegistro.egresado) {
            this.usuarioRegistro.nivel_id = this.egresado.id
        }

        this.usuarioRegistro.typoEnvio = typoEnvio

        let usuario = this.usuarioRegistro.toUsuario()
        ;(usuario as any).check_from_nomina = 1

        usuario["is_registro"] = true

        this.usuariosService.enableIgnoreModel()
        this.usuariosService.save(usuario).then(response => {
            this.usuariosService.disableIgnoreModel()
            const verifyFromNomina = false
            this.modalSelectTipoEnvioComponent.close()
            return this.loginGeneral(this.authService.loginRegistroOPassword(response), verifyFromNomina)
        })
    }

    back() {
        this.router.navigate([""])
    }

    goOmitir() {
        this.genericModal.close()
        this.selectEnvioConfirmacion = true
        this.omitir = true
        this.nextStep()
    }

    nextStep() {
        this.checkStep()
        if (this.omitir) {
            this.registerStepsService.goToStepIfValid(this.currentStep + 1)
        } else {
            if (this.currentStep == 1) {
                this.isTelefono()
            } else {
                this.registerStepsService.goToStepIfValid(this.currentStep + 1)
            }
        }
    }

    previousStep() {
        this.registerStepsService.goToStep(this.currentStep - 1)
    }

    updatePreferencias(preferencias) {
        this.usuarioRegistro.preferencias_carrera = preferencias
    }

    beforeGetEstablecimientos() {
        let lugares = []
        if (this.lugarColegio) lugares = [this.lugarColegio.id]
    }

    whereGetEstablecimientos(establecimientoFilter) {
        return {
            per: 100,
            raw: 1,
            establecimiento: { establecimiento: establecimientoFilter, lugar_id: this.lugarColegio.id },
            include: `[establecimiento_${this.pais}]`
        }
    }

    ngOnDestroy() {
        this.subIndex.unsubscribe()
        this.subSteps.unsubscribe()
    }

    checkStep() {
        const stepValidations = {
            0: [
                "rut",
                "nombre",
                "apellido_materno",
                "apellido_paterno",
                "lugar"
                // "fecha_nacimiento",
                // "genero"
            ],
            1: ["email", "telefono", "password", "password_confirmation"],
            2: ["terminos_y_condiciones"]
        }
        this.registerStepsService.updateStepStatus(
            this.checkControls(stepValidations[this.currentStep]) ? RegisterStepStatus.Error : RegisterStepStatus.Ready,
            this.currentStep
        )
    }

    fixRut() {
        console.log(this.usuarioRegistro)
    }

    checkControls(controls: string[]) {
        return controls.reduce((acc, c) => {
            this.form.controls[c].markAsTouched()
            return acc || this.form.controls[c].status == "INVALID"
        }, false)
    }

    isTelefono() {
        this.telefono = this.form.value.telefono
        if (this.form.value.telefono == "") {
            this.openModal.next()
        } else {
            this.omitir = true
            this.selectEnvioConfirmacion = true
            this.nextStep()
        }
    }

    loginGeneral(response, verifyFromNomina = true) {
        let res = response
        let redirect

        this.nebuAuthService.setToken(res.nebu_token)
        this.reporteRazonesService.loadReporteRazones()
        this.store.dispatch(new GetStoreConfig())

        if (this.sessionService.isFromNomina() && verifyFromNomina) {
            this.router.navigate(["/usuarios/" + res.user.id + "/completar_registro"])
            return
        }

        this.invalidLogin = false
        this.setMessage()

        let host = window.location.hostname
        var new_host = host

        let roles = this.sessionService.getRoles()
        let permisos = this.sessionService.getPermisos()
        let queryParams = { queryParams: { typoEnvio: res.user.tipo_envio } }

        this.store.dispatch(
            new SetCodigoConfirmacionData({
                codigoConfirmacionData: {
                    id: res.user.id,
                    email: res.user.email,
                    tipo_envio: res.user.tipo_envio
                }
            })
        )
        redirect = this.authService.redirectUrl ? this.authService.redirectUrl : "/home"

        let token = response["token"]
        let nebuToken = res.nebu_token
        let user = response["user"]
        if (environment.production) {
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            let prefix = /^(www|alumnos|docentes|admin)/g

            let docentesDomain = ""
            let alumnosDomain = ""
            let adminDomain = ""

            if (environment.domain) {
                docentesDomain = environment.domain.profesores || ""
                alumnosDomain = environment.domain.alumnos || ""
                adminDomain = environment.domain.admin || ""
            }

            let getPrefixRegex = /^([^\.]+)\./
            let docentesPrefixMatch = docentesDomain.match(getPrefixRegex)
            let alumnosPrefixMatch = alumnosDomain.match(getPrefixRegex)
            let adminPrefixMatch = adminDomain.match(getPrefixRegex)

            let docentesPrefix = docentesPrefixMatch ? docentesPrefixMatch[1] : "www"
            let alumnosPrefix = alumnosPrefixMatch ? alumnosPrefixMatch[1] : "alumnos"
            let adminPrefix = adminPrefixMatch ? adminPrefixMatch[1] : "admin"

            let alumnos = new RegExp("^" + alumnosPrefix, "g")

            if (roles.length > 0) {
                if (
                    roles.includes("SuperAdministrador") ||
                    roles.includes("Administrador") ||
                    roles.includes("Monitor")
                ) {
                    new_host = host //Que vaya a donde quiera.
                } else if (roles.includes("Docente") || roles.includes("DocenteDemo")) {
                    if (prefix.test(host)) {
                        new_host = host.replace(/^([a-z]+)\./g, docentesPrefix + ".")
                    } else {
                        new_host = docentesPrefix + "." + host
                    }
                } else {
                    if (config.plataforma.name != "Graduate")
                        if (prefix.test(host)) {
                            new_host = host.replace(/^([a-z]+)\./g, alumnosPrefix + ".")
                        } else {
                            new_host = alumnosPrefix + "." + host
                        }
                }
            } else {
                //Esto no debería pasar nunca
                new_host = host.replace(/^([a-z]+)\./g, alumnosPrefix + ".")
            }
        }
        // Redirect the user
        if (host == new_host) {
            if (config.plataforma.name == "Puntaje Nacional" || config.plataforma.name == "Mineduc") {
                this.router.navigate([redirect], queryParams)
            } else {
                this.router.navigate([redirect])
            }
        } else {
            this.authService.logout() // Si lo redirijo, entonces no tengo que estar logueado acá.
            window.location.href =
                "http://" + new_host + redirect.replace(host, "") + "?token=" + token + "&nebu_token=" + nebuToken
        }

        return res
    }

    setMessage() {
        this.message = "Logged " + (this.authService.isLoggedIn() ? "in" : "out")
    }
}
