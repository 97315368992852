<filtros-clasificaciones-evaluacion-tipo
    *ngIf="showFilterClasificacionEvTipo"
    [asignaturaId]="asignaturaId"
    [evaluacionTipo]="evaluacionTipo"
    (changeFilter)="onChangeFilter($event)"
></filtros-clasificaciones-evaluacion-tipo>
<simple-search-filter
    *ngIf="enableSearch && !cursoSearch"
    (updateSearch)="updateSearch($event)"
    (clearSearch)="updateSearch($event)"
    [autoclearEnabled]="false"
></simple-search-filter>
<curso-search-filter
    *ngIf="enableSearch && cursoSearch"
    (classificationSearch)="classificationSearch($event)"
    (clearSearch)="classificationSearch($event)"
    [autoclearEnabled]="false"
    [clasificaciones]="clasificaciones"
    placeholder="{{ 'estudiar.streaming.search.search_placeholder' | t }}"
></curso-search-filter>
<div *ngIf="!loadingLayout.loading && materiales && materiales.length != 0">
    <div *ngFor="let m of materiales" class="material-container">
        <material-with-thumbnail
            [tracking]="tracking"
            [material]="m"
            [enableMaterialRelacionado]="enableMaterialRelacionado"
            [enableModal]="enableModal"
        ></material-with-thumbnail>
    </div>
</div>

<div *ngIf="!loadingLayout.loading && (!materiales || (materiales && materiales.length == 0))">
    <p class="no-content">Material no disponible.</p>
</div>

<loading-layout #loadingLayout>
    <paginator
        [getList]="setData.bind(this)"
        [class.hidePaginator]="!materiales || (materiales && materiales.length == 0)"
        [per]="12"
    ></paginator>
</loading-layout>
