import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "realizar_plan_compartido.component.html"
})
export class RealizarPlanCompartidoComponent implements OnInit, OnDestroy {
    planPersonal: PlanPersonal
    paramsSub: Subscription
    @ViewChild("planPersonalLoading", { static: true }) planPersonalLoading: LoadingLayoutComponent

    constructor(
        protected titleService: TitleService,
        private route: ActivatedRoute,
        private planPersonalService: PlanPersonales
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.realizar_plan_compartido")
        this.paramsSub = this.route.params.subscribe(query => {
            let planId = +query["id"]
            this.planPersonalLoading.standby()
            this.planPersonalService
                .find(planId, {
                    include:
                        "[plan_personal_tipo,generador_instrumento:asignatura,plan_personal_ciclos:plan_personal_sesiones:plan_personal_contenidos:[plan_personal_material_instancias,plan_personal_evaluacion_instancias:evaluacion_instancia]]"
                })
                .then((plan: PlanPersonal) => {
                    this.planPersonal = plan
                    this.planPersonalLoading.ready()
                })
        })
    }

    ngOnDestroy() {
        this.paramsSub.unsubscribe()
    }
}
