import { RestrictedLayoutComponent } from "../layouts/restricted_layout/restricted_layout.component"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    RealizarEnsayoComponent,
    EvaluacionesCorregirComponent,
    EnsayoInstanciasComponent,
    EnsayosMensualesComponent,
    EvaluacionesCompartidasComponent,
    GenerarEnsayoComponent,
    ImprimirEnsayoComponent,
    ResultadosEnsayoComponent,
    RealizarEvaluacionInteractivaComponent
} from "@puntaje/puntaje/core"
import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { LoadAsignaturaGuard } from "@puntaje/puntaje/services"

import { HomeEnsayosComponent } from "./home_ensayos.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { ProgresoAlumnoComponent, EstadisticasAlumnoComponent } from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/subir",
        component: RestrictedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionesCorregirComponent,
                data: { typePlatform: "alumnoTwo", perfil: "alumno", titulo: "Subir resultados de Evaluaciones" },
                canActivate: [TokenGuard, AuthGuard, LoadAsignaturaGuard]
            }
        ]
    },
    // { path: 'evaluacion_instancias/corregir', component: EvaluacionesCorregirComponent, canActivate: [TokenGuard, AuthGuard, LoadAsignaturaGuard] },
    {
        path: "ensayos/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [
            { path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard, LoadAsignaturaGuard] }
        ]
    },
    {
        path: "ensayos/:id/imprimir",
        component: RestrictedLayoutComponent,
        children: [
            { path: "", component: ImprimirEnsayoComponent, canActivate: [TokenGuard, AuthGuard, LoadAsignaturaGuard] }
        ]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar_interactiva",
        component: EvaluationLayoutComponent,
        children: [
            { path: "", component: RealizarEvaluacionInteractivaComponent, canActivate: [TokenGuard, AuthGuard] }
        ]
    },
    {
        path: "evaluaciones/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "ensayos_breves",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayosMensualesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/corregir",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCorregirComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    //Calugas
    {
        path: "ensayos/estadisticas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticasAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "ensayos/generar",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: { hideButtonDescargar: true }
            }
        ]
    },
    {
        path: "ensayos/mi_progreso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ProgresoAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "ensayos/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/generar_prueba/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    { path: "pruebas/estadisticas", component: EstadisticasAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    { path: "pruebas/mi_progreso", component: ProgresoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas_de_transicion/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCompartidasComponent }]
    },
    {
        path: "pruebas_de_transicion",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas_de_transicion/recomendados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
