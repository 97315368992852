import { Component, OnInit, ViewChild, Output, Input, EventEmitter, SimpleChanges } from "@angular/core"
import { Subscription } from "rxjs"

@Component({
    selector: "materiales-cursos",
    templateUrl: "materiales_cursos.component.html",
    styleUrls: ["materiales_cursos.component.scss"]
})
export class MaterialesCursosComponent implements OnInit {
    categoria_id: number = 1
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() tipoCursos: string = ""
    cursos: any = []

    constructor() {
        this.cursos = [
            { categoria: "Primero básico", categoria_short: "1<sup>ero</sup> básico", categoria_id: 1 },
            { categoria: "Segundo básico", categoria_short: "2<sup>do</sup> básico", categoria_id: 2 },
            { categoria: "Tercero básico", categoria_short: "3<sup>ro</sup> básico", categoria_id: 3 },
            { categoria: "Cuarto básico", categoria_short: "4<sup>to</sup> básico", categoria_id: 4 },
            { categoria: "Quinto básico", categoria_short: "5<sup>to</sup> básico", categoria_id: 5 },
            { categoria: "Sexto básico", categoria_short: "6<sup>to</sup> básico", categoria_id: 6 },
            { categoria: "Séptimo básico", categoria_short: "7<sup>mo</sup> básico", categoria_id: 7 },
            { categoria: "Octavo básico", categoria_short: "8<sup>vo</sup> básico", categoria_id: 8 },
            { categoria: "Primero Medio", categoria_short: "1<sup>ero</sup> medio", categoria_id: 9 },
            { categoria: "Segundo Medio", categoria_short: "2<sup>do</sup> medio", categoria_id: 10 }
        ]
        this.categoria_id = this.cursos[0].categoria_id
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"]) {
            this.categoria_id = this.cursos[0].categoria_id
        }
        if (changes["tipoCursos"]) {
            this.setCursos()
        }
    }

    setCursos() {
        //TODO: Cambiar esto por alguna consulta a la base de datos algún día
        switch (this.tipoCursos) {
            case "simce":
                this.cursos = [
                    { categoria: "Segundo básico", categoria_short: "2<sup>do</sup> básico", categoria_id: 2 },
                    { categoria: "Cuarto básico", categoria_short: "4<sup>to</sup> básico", categoria_id: 4 },
                    { categoria: "Sexto básico", categoria_short: "6<sup>to</sup> básico", categoria_id: 6 },
                    { categoria: "Octavo básico", categoria_short: "8<sup>vo</sup> básico", categoria_id: 8 },
                    { categoria: "Segundo medio", categoria_short: "2<sup>do</sup> medio", categoria_id: 10 }
                ]
                break

            case "saber":
                this.cursos = [
                    { categoria: "Tercero", categoria_short: "3<sup>ro</sup>", categoria_id: 12192 },
                    { categoria: "Quinto", categoria_short: "5<sup>to</sup>", categoria_id: 12193 },
                    { categoria: "Séptimo", categoria_short: "7<sup>mo</sup>", categoria_id: 12458 },
                    { categoria: "Noveno", categoria_short: "9<sup>no</sup>", categoria_id: 12194 }
                ]
                break

            case "saber11":
                this.cursos = [{ categoria: "Once", categoria_short: "11<sup>avo</sup>", categoria_id: 1000683 }]
                break

            case "graduate":
                this.cursos = [
                    { categoria: "Primer ciclo", categoria_short: "1<sup>er</sup> ciclo", categoria_id: 12381 },
                    { categoria: "Segundo ciclo", categoria_short: "2<sup>do</sup> ciclo", categoria_id: 12305 }
                ]
                break

            case "curricular":
                this.cursos = [
                    { categoria: "Primero básico", categoria_short: "1<sup>ero</sup> básico", categoria_id: 1 },
                    { categoria: "Segundo básico", categoria_short: "2<sup>do</sup> básico", categoria_id: 2 },
                    { categoria: "Tercero básico", categoria_short: "3<sup>ro</sup> básico", categoria_id: 3 },
                    { categoria: "Cuarto básico", categoria_short: "4<sup>to</sup> básico", categoria_id: 4 },
                    { categoria: "Quinto básico", categoria_short: "5<sup>to</sup> básico", categoria_id: 5 },
                    { categoria: "Sexto básico", categoria_short: "6<sup>to</sup> básico", categoria_id: 6 },
                    { categoria: "Séptimo básico", categoria_short: "7<sup>mo</sup> básico", categoria_id: 7 },
                    { categoria: "Octavo básico", categoria_short: "8<sup>vo</sup> básico", categoria_id: 8 }
                ]
                break

            case "curso colombia":
                this.cursos = [
                    { categoria: "Primero", categoria_short: "1<sup>ero</sup>", categoria_id: 1007492 },
                    { categoria: "Segundo", categoria_short: "2<sup>do</sup>", categoria_id: 1007493 },
                    { categoria: "Tercero", categoria_short: "3<sup>ro</sup>", categoria_id: 1007494 },
                    { categoria: "Cuarto", categoria_short: "4<sup>to</sup>", categoria_id: 1007495 },
                    { categoria: "Quinto", categoria_short: "5<sup>to</sup>", categoria_id: 1007496 },
                    { categoria: "Sexto", categoria_short: "6<sup>to</sup>", categoria_id: 1007497 },
                    { categoria: "Séptimo", categoria_short: "7<sup>mo</sup>", categoria_id: 1000399 },
                    { categoria: "Octavo", categoria_short: "8<sup>vo</sup>", categoria_id: 1000398 },
                    { categoria: "Noveno", categoria_short: "9<sup>no</sup>", categoria_id: 1000397 },
                    { categoria: "Décimo", categoria_short: "10<sup>mo</sup>", categoria_id: 1000396 },
                    { categoria: "Undécimo", categoria_short: "11<sup>mo</sup>", categoria_id: 1000395 }
                ]
                break

            case "cursos-mx":
                this.cursos = [
                    {
                        categoria: "Primero de primaria",
                        categoria_short: "1<sup>ero</sup> primaria",
                        categoria_id: 1004075
                    },
                    {
                        categoria: "Segundo de primaria",
                        categoria_short: "2<sup>do</sup> primaria",
                        categoria_id: 1004076
                    },
                    {
                        categoria: "Tercero de primaria",
                        categoria_short: "3<sup>ro</sup> primaria",
                        categoria_id: 1004115
                    },
                    {
                        categoria: "Cuarto de primaria",
                        categoria_short: "4<sup>to</sup> primaria",
                        categoria_id: 1004140
                    },
                    {
                        categoria: "Quinto de primaria",
                        categoria_short: "5<sup>to</sup> primaria",
                        categoria_id: 1004166
                    },
                    {
                        categoria: "Sexto de primaria",
                        categoria_short: "6<sup>to</sup> primaria",
                        categoria_id: 1004196
                    },
                    {
                        categoria: "Primero de secundaria",
                        categoria_short: "1<sup>ero</sup> secundaria",
                        categoria_id: 1004232
                    },
                    {
                        categoria: "Segundo de secundaria",
                        categoria_short: "2<sup>do</sup> secundaria",
                        categoria_id: 1004262
                    },
                    {
                        categoria: "Tercero de secundaria",
                        categoria_short: "3<sup>ro</sup> secundaria",
                        categoria_id: 1004290
                    }
                ]
                break

            case "guanajuato":
                this.cursos = [
                    {
                        categoria: "Primer Semestre Bachillerato",
                        categoria_short: "1<sup>er</sup> Semestre",
                        categoria_id: 1007936
                    },
                    {
                        categoria: "Segundo Semestre Bachillerato",
                        categoria_short: "2<sup>do</sup> Semestre",
                        categoria_id: 1007937
                    },
                    {
                        categoria: "Tercer Semestre Bachillerato",
                        categoria_short: "3<sup>er</sup> Semestre",
                        categoria_id: 1007938
                    },
                    {
                        categoria: "Cuarto Semestre Bachillerato",
                        categoria_short: "4<sup>to</sup> Semestre",
                        categoria_id: 1007939
                    },
                    {
                        categoria: "Quinto Semestre Bachillerato",
                        categoria_short: "5<sup>to</sup> Semestre",
                        categoria_id: 1007940
                    },
                    {
                        categoria: "Sexto Semestre Bachillerato",
                        categoria_short: "6<sup>to</sup> Semestre",
                        categoria_id: 1007941
                    }
                ]

                break

            default:
                this.cursos = [
                    { categoria: "Primero básico", categoria_short: "1<sup>ero</sup> básico", categoria_id: 1 },
                    { categoria: "Segundo básico", categoria_short: "2<sup>do</sup> básico", categoria_id: 2 },
                    { categoria: "Tercero básico", categoria_short: "3<sup>ro</sup> básico", categoria_id: 3 },
                    { categoria: "Cuarto básico", categoria_short: "4<sup>to</sup> básico", categoria_id: 4 },
                    { categoria: "Quinto básico", categoria_short: "5<sup>to</sup> básico", categoria_id: 5 },
                    { categoria: "Sexto básico", categoria_short: "6<sup>to</sup> básico", categoria_id: 6 },
                    { categoria: "Séptimo básico", categoria_short: "7<sup>mo</sup> básico", categoria_id: 7 },
                    { categoria: "Octavo básico", categoria_short: "8<sup>vo</sup> básico", categoria_id: 8 },
                    { categoria: "Primero Medio", categoria_short: "1<sup>ero</sup> medio", categoria_id: 9 },
                    { categoria: "Segundo Medio", categoria_short: "2<sup>do</sup> medio", categoria_id: 10 }
                ]
                break
        }
        this.categoria_id = this.cursos[0].categoria_id
        this.onReady.emit(this.categoria_id)
    }

    onSelectCurso(categoria_id: number) {
        this.categoria_id = categoria_id
        this.onReady.emit(this.categoria_id)
    }

    setCursosClasses() {
        let clase = "Cursos_" + this.cursos.length
        return clase
    }
}
