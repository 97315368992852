import { Component, OnInit } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./realizar_evaluacion_interactiva.component.html"
})
export class RealizarEvaluacionInteractivaComponent implements OnInit {
    sub: Subscription
    evaluacionId: number

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.evaluacionId = +params["id"]
        })
    }
}
