import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Asignaturas, Asignatura } from "@puntaje/nebulosa/api-services"
import { PlanPersonalUsuarios, PlanPersonalUsuario, PlanPersonales, PlanPersonal } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { select, Store } from "@ngrx/store"
import { selectSelectedAsignaturaId, selectSelectedEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { combineLatest, Subscription } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "historial-planes-compartidos",
    templateUrl: "./historial-planes-compartidos.component.html",
    styleUrls: ["./historial-planes-compartidos.component.scss"]
})
export class HistorialPlanesCompartidosComponent implements OnInit {
    asignaturaId: number
    lista_asignaturas: any
    tareas = []
    reforzamientos = []
    asignatura = null
    user: any
    openingTab: string = "tareas"
    tipoInstrumento = "ensayo"
    sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    asignaturaId$ = this.store.pipe(
        select(selectSelectedAsignaturaId),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected auth: AuthService,
        protected planPersonalesService: PlanPersonales,
        protected asignaturasService: Asignaturas,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_personales.historial_planes_compartidos")
        this.user = this.auth.getUserData()

        this.sub = combineLatest(this.asignaturaId$, this.evaluacionTipo$).subscribe(
            ([asignaturaId, evaluacionTipo]) => {
                this.asignaturaId = asignaturaId
                this.tipoInstrumento = evaluacionTipo
                this.onSelectAsignatura(asignaturaId)

                this.cdr.detectChanges()
            }
        )
    }

    setTabClasses(tab: string) {
        let classes = {
            active: this.openingTab == tab,
            in: this.openingTab == tab
        }
        return classes
    }

    onSelectAsignatura(asignaturaId) {
        this.asignaturaId = asignaturaId
        this.loadingLayout.standby()
        this.asignaturasService
            .find(this.asignaturaId, {
                with_generador_instrumentos: 1,
                tipo_instrumento: this.tipoInstrumento
            })
            .then((asignatura: Asignatura) => {
                let params = {
                    // include: '[plan_personal_ciclos:evaluacion,plan_personal_instancias,plan_personal_usuarios:receptor,plan_personal_tipo]',
                    render_options: {
                        include: {
                            // 'plan_personal_usuarios',
                            plan_personal_usuarios: { include: "receptor" },
                            plan_personal_tipo: null,
                            plan_personal_ciclos: { include: "evaluacion" },
                            plan_personal_instancias: {
                                methods: "avance"
                            }
                            // plan_personal_usuarios: {include: 'receptor'},
                        },
                        methods: "total_ciclos"
                    },
                    usuario_id: this.user.id,
                    generador_instrumento_ids: asignatura.generador_instrumentos.map(x => x["id"])
                }
                this.planPersonalesService.where(params).then((planes: PlanPersonal[]) => {
                    this.tareas = []
                    this.reforzamientos = []
                    planes.forEach((p: PlanPersonal) => {
                        if (p.plan_personal_tipo.tipo == "tarea") this.tareas.push(p)
                        else if (p.plan_personal_tipo.tipo == "reforzamiento") this.reforzamientos.push(p)
                    })
                    this.loadingLayout.ready()
                })
            })
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe()
    }
}
