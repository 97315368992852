<div class="banner clearfix">
    <div class="banner_left"></div>
    <div class="banner_right"></div>
    <div class="banner_text_table">
        <div class="banner_text_row">
            <div class="banner_text">
                Materiales Saber
                <span class="subtitle">
                    Aquí encontrarás material dedicado exclusivamente a contenido evaluado por las pruebas Saber 3º, 5º
                    y 9º.
                </span>
            </div>
        </div>
    </div>
</div>
