import {
    PlanClasesDeAlumnosComponent,
    PlanClaseAlumnoComponent,
    ClasePlanClaseComponent,
    PlanesEstudiosComponent,
    PlanEstudioSesionComponent,
    PlanEstudioSesionesComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"

import { HomePlanesEstudiosComponent } from "./home_planes_estudios.component"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [AuthGuard, TokenGuard],
                data: { titulo: "" }
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard, TokenGuard],
                data: { showVerLibro: false }
            }
        ]
    },
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesDeAlumnosComponent,
                canActivate: [AuthGuard, TokenGuard],
                data: { planClaseRoute: "plan_clases", showPlanesCompartidosProfesor: false }
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
