import { Component } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Template simple para páginas para usuario sin tipo de usuario en particular, particularmente
para la recuperación de contraseña.
Implementa una sección de contenido.

simple-shared-layout-contenido

#################################*/

@Component({
    templateUrl: "simple_shared_layout.component.html",
    selector: "simple-shared-layout",
    styleUrls: ["simple_shared_layout.component.scss"]
})
export class SimpleSharedLayoutComponent {}
