<menu-evaluacion-tipo-asignaturas
    [evaluacionTipos]="[tipoAsignatura]"
    [showTitleOverMenu]="false"
></menu-evaluacion-tipo-asignaturas>

<materiales-with-thumbnails
    *ngIf="cursosLoaded"
    [tracking]="tracking"
    [asignaturaId]="asignatura_id"
    [tipoMaterial]="3"
    [evaluacionTipo]="tipoAsignatura"
    [sortByDate]="'desc'"
    [enableMaterialRelacionado]="enableMaterialRelacionado"
    [enableModal]="false"
    [cursoSearch]="true"
    [cursosId]="cursosId"
></materiales-with-thumbnails>
