import { Component, OnInit, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "materiales-v1.component.html",
    styleUrls: ["materiales-v1.component.scss"]
})
export class MaterialesV1Component implements OnInit, OnDestroy {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    clasificaciones: string[]
    private sub: Subscription
    subData: Subscription
    enableResponsive: boolean = false
    titulo: string = "Material de apoyo"
    enableMaterialesDirigidos: boolean = true
    tipoCursos: string = config.plataforma.evaluacionDefault
    tipoAsignaturas: string = config.plataforma.evaluacionDefault
    showMaterialVisible: boolean
    enableProfesores = true

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.materiales_v1")
        this.titulo = config.app.name == "profesores" ? "Material de apoyo para Profesores" : "Material de apoyo"
        this.sub = this.route.queryParams.subscribe(query => {
            this.asignaturaId = +query["asignatura_id"]
        })
        this.subData = this.route.data.subscribe(data => {
            this.titulo = data.titulo ?? this.titulo
            this.enableMaterialesDirigidos = data.enableMaterialesDirigidos ?? this.enableMaterialesDirigidos
            this.tipoCursos = data.tipoCursos ?? this.tipoCursos
            this.tipoAsignaturas = data.tipoAsignaturas ?? this.tipoAsignaturas
            this.showMaterialVisible = data.showMaterialVisible ?? this.showMaterialVisible
            this.enableProfesores = data.enableProfesores ?? this.enableProfesores
        })
        this.enableResponsive = window.innerWidth <= 900
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onReadyCurso(curso_id) {
        this.cursoId = curso_id
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
    }

    onReadyClasificaciones(event) {
        this.clasificaciones = event
    }
}
