<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse nav-justified-menu">
        <ul class="nav navbar-nav">
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/dudas_categorias']" role="button">Comunidad</a>
                <!--<a [routerLink]="['/dudas']" role="button"><ogr-icon name='dialogos' align="top"></ogr-icon>&nbsp;Comunidad</a>-->
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/ensayos']" role="button">Generador de Ensayos</a>
            </li>
            <!-- <li routerLinkActive="active-menu">
                <a [routerLink]="['/pruebas']" role="button">
                    <ogr-icon name="guia" align="top"></ogr-icon>
                    &nbsp;Pruebas curriculares
                </a>
            </li> -->
            <!-- <li routerLinkActive="active-menu">
                <a [routerLink]="['/streaming']" role="button">
                    <ogr-icon name="video" align="top"></ogr-icon>
                    &nbsp;Videos y clases
                </a>
                <i checkStreamingOnline name="camara-video" class="live">
                    <ogr-icon name="camara-video" class="icono"></ogr-icon>
                    en vivo
                </i>
            </li> -->
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/bibliotecas']" role="button">Biblioteca</a>
                <a [routerLink]="['/biblioteca']" style="display: none; pointer-events: none; visibility: hidden"></a>
                <a [routerLink]="['/materiales']" style="display: none; pointer-events: none; visibility: hidden"></a>
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/plan_clases']" role="button">Planes de Clase</a>
            </li>
        </ul>
    </div>
</nav>
