<ll-titulo>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-titulo>

<ll-tabs>
    <ul class="nav nav-tabs nav-justified">
        <li
            *ngIf="!disableEnvivo"
            role="presentation"
            (click)="tabChanged(1, 'pestaña-envivo')"
            class="active in"
        >
            <a data-toggle="tab" href="#en-vivo">{{ "estudiar.streaming.vivo" | t }}</a>
        </li>
        <li 
            *ngIf="showTabUltimaClase" 
            role="presentation" 
            (click)="tabChanged(2, 'pestaña-última-clase')"
        >
            <a data-toggle="tab" href="#ultima-clase">{{ "estudiar.streaming.ultima_clase" | t }}</a>
        </li>
        <li
            role="presentation"
            class="last-child"
            [class.active]="disableEnvivo"
            [class.in]="disableEnvivo"
        >
            <a data-toggle="tab" href="#videos">{{ "estudiar.streaming.anteriores" | t }}</a>
        </li>
    </ul>
</ll-tabs>

<div class="clearfix">
    <div class="tab-content">
        <div *ngIf="!disableEnvivo" id="en-vivo" class="tab-pane fade active in">
            <streaming-live 
                #streamingLive 
                *ngIf="currentTab == 1"
                [tipoStreaming]="tipoStreaming"
                [channelAliasType]="channelAliasType"
                [channelAliasKey]="channelAliasKey" 
            ></streaming-live>
        </div>
        <div *ngIf="showTabUltimaClase" id="ultima-clase" class="tab-pane fade">
            <streaming-last 
                [tipoStreaming]="tipoStreaming"
                [channelAliasType]="channelAliasType"
                [channelAliasKey]="channelAliasKey"
            ></streaming-last>
        </div>
        <div
            id="videos"
            class="tab-pane fade"
            [class.active]="disableEnvivo"
            [class.in]="disableEnvivo"
        >
            <streaming-videos-list
                [tracking]="tracking"
                [enableMaterialRelacionado]="true"
                [tipoStreaming]="tipoStreaming"
            ></streaming-videos-list>
        </div>
    </div>
</div>
