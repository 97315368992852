import { Input, Component, EventEmitter, Output, OnInit } from "@angular/core"
import { UntypedFormGroup, Validators } from "@angular/forms"
import { PreferenciaCarrera, Carreras, Carrera, Universidades, Universidad } from "@puntaje/puntaje/api-services"

@Component({
    selector: "embed-preferencia-carrera",
    templateUrl: "embed_preferencia_carreras.form.component.html",
    styleUrls: ["embed_preferencia_carreras.scss"]
})
export class EmbedPreferenciaCarrerasComponent implements OnInit {
    @Input() universidad: string
    @Input() amount: number
    @Input() form: UntypedFormGroup
    carreras: Carrera[]
    @Output() emit_preferencias: EventEmitter<PreferenciaCarrera[]> = new EventEmitter<PreferenciaCarrera[]>()
    preferencia_carrera: PreferenciaCarrera[] = []
    params: any[] = []
    keys: string[] = []
    constructor(private carrerasService: Carreras, private universidadesService: Universidades) {}

    ngOnInit() {
        this.setSelectsAndModels()
        if (this.universidad) {
            this.universidadesService
                .where({ universidad: { universidad: this.universidad } })
                .then((uni: Universidad[]) => {
                    let universidad = uni as any as Universidad
                    this.carrerasService
                        .where({ carrera: { universidad_id: universidad.id } })
                        .then((response: Carrera[]) => {
                            this.carreras = response
                        })
                })
        } else {
            this.carrerasService.where().then((response: Carrera[]) => {
                this.carreras = response
            })
        }
    }

    setSelectsAndModels() {
        let amount = this.amount ? this.amount : 3
        for (var i = 0; i < amount; i++) {
            let label = "Seleccionar preferencia " + (i + 1)
            let key = "preferencia_carrera_" + (i + 1)
            this.params[i] = {
                type: "select",
                label: label,
                validations: [Validators.required],
                visible: true,
                key: key
            }
            this.preferencia_carrera.push(new PreferenciaCarrera())
        }
    }

    setVals(index, val) {
        this.preferencia_carrera[index].carrera_id = Number(val)
        this.preferencia_carrera[index].orden = index + 1
        this.emit_preferencias.next(this.preferencia_carrera)
    }
}
