import { NgModule, ModuleWithProviders } from "@angular/core"
import { CommonModule } from "@angular/common"

import { EntrenadorModule } from "@puntaje/puntaje/new-modules/entrenador"
import { EntrenadorUsuariosEditComponent } from "./entrenador_usuarios_edit.component"
import { CUILayoutsModule } from "@puntaje/shared/cui"

@NgModule({
    imports: [CommonModule, EntrenadorModule, CUILayoutsModule],
    declarations: [EntrenadorUsuariosEditComponent],
    exports: [EntrenadorUsuariosEditComponent]
})
export class PuntajeEntrenadorModule {}
