<table class="table">
    <thead>
        <tr>
            <th>Nombre</th>
            <th>Evaluación</th>
            <th>Avance</th>
            <th>Acciones</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let plan_personal of planes">
            <td>{{ plan_personal.plan_personal }}</td>
            <td>{{ plan_personal.plan_personal_ciclos[0].evaluacion?.evaluacion }}</td>
            <td>
                {{
                    plan_personal.plan_personal_instancias.length == 0
                        ? 0
                        : plan_personal.plan_personal_instancias[0].avance
                }}/{{ plan_personal.total_ciclos }}
            </td>
            <td><a routerLink="/planes_compartidos/{{ plan_personal.id }}">Realizar</a></td>
        </tr>
    </tbody>
</table>
