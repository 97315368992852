<div class="clasificaciones">
    <ng-container *ngFor="let c of listaClasificaciones | keys; let i = index">
        <button
            *ngIf="listaClasificaciones[c].enabled"
            [class.selected]="clasificacionSelected == i"
            class="btn btn-default btn-clasificacion"
            (click)="show(listaClasificaciones[c].clasificacion, i)"
        >
            <ogr-icon [name]="listaClasificaciones[c].icono" class="icono"></ogr-icon>
            {{ listaClasificaciones[c].label }}
        </button>
    </ng-container>
</div>
