import { Component, OnInit, ViewChild, Input, HostBinding } from "@angular/core"
import { Duda, Dudas, DudaCategoria, DudaCategorias } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "dudas-showcase",
    templateUrl: "dudas_showcase.component.html",
    styleUrls: ["dudas_showcase.component.scss"]
})
export class DudasShowcaseComponent implements OnInit {
    dudas: Duda[] = []
    @HostBinding("class") innerClass = "showcasebox_style_1"
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @Input() maximoDudas: number = 5
    @Input() enableContentStyle: boolean = false

    constructor(protected dudasService: Dudas) {}

    ngOnInit() {
        this.showAll(1, this.maximoDudas)
    }

    showAll = (page: number, per: number) => {
        this.loadingLayout.standby()
        let params = {
            page: page,
            per: per,
            sort_by: "created_at",
            order: "desc",
            methods: "participacion",
            include: "[usuario,duda_categorias]"
        }

        return this.dudasService.where(params).then((dudas: Duda[], total: number) => {
            this.dudas = dudas
            this.loadingLayout.ready()
            return total
        })
    }
}
