<loading-layout #loadingLayout>
    <div class="row">
        <div class="col-md-6 col-sm-12">
            <form *ngIf="usuarioAvatarEdit" novalidate [formGroup]="formAvatar">
                <cui-section-headline>Foto de perfil</cui-section-headline>
                <div class="avatar-edit-container">
                    <cui-button class="btn_style edit-avatar-btn" type="button" (click)="toggleAvatarEdit($event)">
                        Cambiar imagen
                    </cui-button>
                    <div *ngIf="usuarioAvatarEdit" class="avatar-container">
                        <loading-layout #loadingLayoutAvatar [opaque]="true" [loading]="false">
                            <img
                                class="avatar"
                                alt="avatar"
                                *ngIf="usuarioAvatarEdit.avatar"
                                [src]="usuario.avatar | trustedurl"
                                (load)="onLoadProfileImage()"
                            />
                            <img
                                class="avatar"
                                alt="no avatar"
                                *ngIf="!usuarioAvatarEdit.avatar"
                                src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png"
                                (load)="onLoadProfileImage()"
                            />
                        </loading-layout>
                    </div>
                    <div id="avatarInput" [class.show-file-name]="usuarioAvatarEdit.file">
                        <form-input
                            [form]="formAvatar"
                            [params]="paramsAvatar['file']"
                            [(value)]="usuarioAvatarEdit['file']"
                            [key]="'file'"
                            [xhr_upload]="s3Service.xhr_upload"
                        ></form-input>
                        <div class="avatar-buttons clearfix">
                            <cui-button
                                type="button"
                                class="btn_style pull-right"
                                *ngIf="usuarioAvatarEdit.file"
                                (click)="saveProfileImage()"
                            >
                                Aceptar
                            </cui-button>
                            <cui-button
                                buttonType="cancel"
                                type="button"
                                class="btn_cancel pull-right"
                                *ngIf="usuarioAvatarEdit.file"
                                (click)="cancelNewAvatar()"
                            >
                                Cancelar
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6 col-sm-12">
            <cui-section-headline>Opciones</cui-section-headline>
            <cui-button *ngIf="!edit_password" class="btn_style" type="button" (click)="showEditPassword()">
                Cambiar contraseña
            </cui-button>
            <div *ngIf="edit_password">
                <div class="edit-pass">
                    <cui-button
                        buttonType="cancel"
                        class="btn_cancel edit-user-info-btn"
                        type="button"
                        (click)="showEditPassword()"
                    >
                        Cancelar
                    </cui-button>
                    <usuario-edit-password></usuario-edit-password>
                </div>
            </div>
            <br />
            <br />
            <ng-container *ngIf="enableTutor">
                <cui-section-headline>Tutores</cui-section-headline>
                <tutores [usuario]="usuario"></tutores>
            </ng-container>

            <cui-section-headline>Privacidad</cui-section-headline>
            <div *ngIf="usuario" class="form-group">
                <div class="checkbox">
                    <label [attr.for]="'restriccion-datos'">
                        <input
                            id="restriccion-datos"
                            type="checkbox"
                            (change)="activarRestriccionDatos()"
                            [checked]="usuario.restriccion_datos"
                        />
                        <span>
                            No quiero que se envíen mis datos personales a terceros. Acepto no recibir ofertas
                            académicas de mi interés.
                        </span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="usuarioEdit" class="row">
        <div class="col-md-6 col-sm-12">
            <form novalidate [formGroup]="formInfoPersonal">
                <cui-section-headline>Información personal</cui-section-headline>

                <div class="user-info-container">
                    <cui-button
                        class="edit-user-info-btn"
                        [class.btn_style]="!edit_user_info"
                        [class.btn_cancel]="edit_user_info"
                        type="button"
                        (click)="toggleUserInfo($event)"
                    >
                        <span *ngIf="!edit_user_info">Editar</span>
                        <span *ngIf="edit_user_info">Cancelar</span>
                    </cui-button>
                    <div class="user-info" [class.hide]="edit_user_info">
                        <label>{{ params.nombre.label }}:</label>
                        <p id="personal_nombre_completo">{{ usuario?.nombreCompleto() }}</p>
                        <div *ngIf="enableShowCountryData">
                            <div *ngFor="let idP of idPais">
                                <label>{{ idPaisAliases[idP] }}:</label>
                                <p [id]="'personal_' + idP.toLowerCase()">
                                    {{
                                        usuario["usuario_" + pais] && usuario["usuario_" + pais][idP.toLowerCase()]
                                            | easyplaceholder: "-"
                                    }}
                                </p>
                            </div>
                        </div>
                        <label>{{ params.email.label }}:</label>
                        <p id="personal_email">{{ usuario?.email }}</p>
                        <label>{{ params.telefono.label }}:</label>
                        <p id="personal_telefono">{{ usuario?.usuario_chile?.telefono | easyplaceholder: "-" }}</p>
                        <label>{{ params.fecha_nacimiento.label }}:</label>
                        <p id="personal_fecha_nacimiento">
                            {{ usuario?.fecha_nacimiento | prettydate | easyplaceholder: "No definida" }}
                        </p>
                    </div>
                    <!--Edicion-->
                    <div class="user-edit" [class.show]="edit_user_info">
                        <form-input
                            id="personal_form_id"
                            [form]="formInfoPersonal"
                            [params]="params['id']"
                            [(value)]="usuarioEdit['id']"
                            [key]="'id'"
                        ></form-input>
                        <form-input
                            id="personal_form_nombre"
                            [form]="formInfoPersonal"
                            [params]="params['nombre']"
                            [(value)]="usuarioEdit['nombre']"
                            [key]="'nombre'"
                        ></form-input>
                        <form-input
                            id="personal_form_apellido_paterno"
                            [form]="formInfoPersonal"
                            [params]="params['apellido_paterno']"
                            [(value)]="usuarioEdit['apellido_paterno']"
                            [key]="'apellido_paterno'"
                        ></form-input>
                        <form-input
                            id="personal_form_apellido_materno"
                            [form]="formInfoPersonal"
                            [params]="params['apellido_materno']"
                            [(value)]="usuarioEdit['apellido_materno']"
                            [key]="'apellido_materno'"
                        ></form-input>
                        <form-input
                            id="personal_form_email"
                            [form]="formInfoPersonal"
                            [params]="params['email']"
                            [(value)]="usuarioEdit['email']"
                            [key]="'email'"
                        ></form-input>
                        <form-input
                            [form]="formInfoPersonal"
                            [params]="params['telefono']"
                            [(value)]="usuarioEdit['telefono']"
                            [key]="'telefono'"
                        ></form-input>
                        <form-input
                            id="personal_form_fecha_nacimiento"
                            [form]="formInfoPersonal"
                            [params]="params['fecha_nacimiento']"
                            [(value)]="usuarioEdit['fecha_nacimiento']"
                            [key]="'fecha_nacimiento'"
                        ></form-input>
                        <div class="clearfix user-edit-buttons">
                            <cui-button (click)="save(formInfoPersonal)" type="button" class="btn_style pull-right">
                                Guardar
                            </cui-button>
                            <cui-button
                                buttonType="cancel"
                                (click)="clear(formInfoPersonal)"
                                type="button"
                                class="btn_cancel pull-right"
                            >
                                Deshacer
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-6 col-sm-12">
            <form novalidate [formGroup]="formInfoAcademica">
                <cui-section-headline>Información académica</cui-section-headline>

                <div class="user-info-container">
                    <cui-button
                        class="edit-user-info-btn"
                        [class.btn_style]="!edit_academic_info"
                        [class.btn_cancel]="edit_academic_info"
                        type="button"
                        (click)="toggleAcademicInfo($event)"
                    >
                        <span *ngIf="!edit_academic_info">Editar</span>
                        <span *ngIf="edit_academic_info">Cancelar</span>
                    </cui-button>
                    <div class="user-info" [class.hide]="edit_academic_info">
                        <!-- TRADUCCIONES -->
                        <p>
                            <input
                                id="academica_alumno"
                                type="radio"
                                name="profesor"
                                disabled
                                [checked]="!usuario?.profesor"
                            />
                            Soy estudiante
                        </p>
                        <p>
                            <input
                                id="academica_profesor"
                                type="radio"
                                name="profesor"
                                disabled
                                [checked]="usuario?.profesor"
                            />
                            Soy profesor/a
                        </p>
                        <label>{{ params.nivel_id.label }}:</label>
                        <p id="academica_nivel">{{ curso | easyplaceholder: "No definido" }}</p>
                        <label>{{ params.establecimiento_id.label }}:</label>
                        <p id="academica_establecimiento">
                            {{ usuario?.establecimiento | easyplaceholder: "No definido" }}
                        </p>
                        <label>{{ lugarLabel ? lugarLabel : params.lugar.label }}:</label>
                        <p id="academica_lugar">{{ usuario?.lugar | easyplaceholder: "No definida" }}</p>
                        <label>
                            {{ params.nem.label }}:
                            <ng-template #popTemplateNem>
                                <div [innerHTML]="msjNem"></div>
                            </ng-template>
                            <a href="javascript:void(0)" [popover]="popTemplateNem" triggers="focus">
                                <fa [name]="'question-circle-o'" name="question-circle"></fa>
                            </a>
                        </label>
                        <p id="academica_nem">{{ usuario?.usuario_chile?.nem | easyplaceholder: "No definido" }}</p>
                        <label>
                            {{ params.ranking.label }}:
                            <ng-template #popTemplateRanking>
                                <div [innerHTML]="msjRanking"></div>
                            </ng-template>
                            <a href="javascript:void(0)" [popover]="popTemplateRanking" triggers="focus">
                                <fa [name]="'question-circle-o'" name="question-circle"></fa>
                            </a>
                        </label>
                        <p id="academica_ranking">
                            {{ usuario?.usuario_chile?.ranking | easyplaceholder: "No definido" }}
                        </p>

                        <ng-container *ngIf="hasCarreras">
                            <div *ngFor="let preferenciaUniversidad of [0, 1, 2]; let indexUniversidad = index">
                                <label>
                                    Universidad{{ withIPCFT ? " / IP / CFT" : "" }} de preferencia
                                    {{ indexUniversidad + 1 }}:
                                </label>
                                <p [id]="'academica_preferencia_universidad_' + indexUniversidad">
                                    {{
                                    usuarioEdit?.preferenciaUniversidades?.[indexUniversidad]?.universidad?.universidad |
                                    easyplaceholder: "-"
                                    }}
                                </p>
                            </div>

                            <label>Área de interés:</label>
                            <p [id]="'academica_preferencia_area_'">
                                {{ usuarioEdit.preferenciaAreas[0]?.area | easyplaceholder: "-" }}
                            </p>

                            <!-- <div *ngFor="let preferenciaArea of usuarioEdit.preferenciaAreas; let indexArea = index">
                                <label>Área de interés:</label>
                                <p [id]="'academica_preferencia_area_' + indexArea">
                                    {{ preferenciaArea?.area?.area | easyplaceholder: "-" }}
                                </p>
                            </div> -->
                        </ng-container>
                    </div>
                    <!--Edicion-->
                    <div class="user-edit" [class.show]="edit_academic_info">
                        <form-input
                            id="academica_form_profesor"
                            [form]="formInfoAcademica"
                            [params]="params['profesor']"
                            [(value)]="usuarioEdit['profesor']"
                            [key]="'profesor'"
                        ></form-input>
                        <form-input
                            id="academica_form_nivel"
                            [form]="formInfoAcademica"
                            [params]="params['nivel_id']"
                            [(value)]="usuarioEdit['nivel_id']"
                            [key]="'nivel_id'"
                        ></form-input>

                        <label [attr.for]="'lugarColegio'">Comuna de tu colegio</label>
                        <autocomplete
                            [placeholder]="'Escribe y selecciona entre las opciones'"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="lugarColegio"
                            [options]="filteredLugarColegio"
                            (filterChange)="getLugarColegio($event)"
                            (controlValue)="updateLugarColegio($event)"
                            class="autocomplete"
                        ></autocomplete>

                        <label [attr.for]="'establecimiento_id'">{{ params["establecimiento_id"].label }}</label>
                        <autocomplete
                            id="academica_form_establecimiento"
                            [placeholder]="'Escribe y selecciona entre las opciones'"
                            [formControlName]="'establecimiento_id'"
                            [options]="filteredEstablecimientos"
                            (filterChange)="getEstablecimientos($event)"
                            (controlValue)="updateColegio($event)"
                            showTextFun="toStringWithIdPais"
                            class="autocomplete"
                        ></autocomplete>
                        <form-input
                            id="academica_form_lugar"
                            [form]="formInfoAcademica"
                            [params]="params['lugar']"
                            [(value)]="usuarioEdit['lugar']"
                            [key]="'lugar'"
                        ></form-input>
                        <form-input
                            id="academica_form_nem"
                            [form]="formInfoAcademica"
                            [params]="params['nem']"
                            [(value)]="usuarioEdit['nem']"
                            [key]="'nem'"
                            [withLabel]="false"
                        >
                            <label for="nem" [attr.for]="'nem'">
                                {{ params["nem"].label }}
                                <ng-template #popTemplateNemEdit>
                                    <div [innerHTML]="msjNem"></div>
                                </ng-template>
                                <a href="javascript:void(0)" [popover]="popTemplateNemEdit" triggers="focus">
                                    <fa [name]="'question-circle-o'" name="question-circle"></fa>
                                </a>
                            </label>
                        </form-input>
                        <form-input
                            id="academica_form_ranking"
                            [form]="formInfoAcademica"
                            [params]="params['ranking']"
                            [(value)]="usuarioEdit['ranking']"
                            [key]="'ranking'"
                            [withLabel]="false"
                        >
                            <label for="ranking" [attr.for]="'ranking'">
                                {{ params["ranking"].label }}
                                <ng-template #popTemplateRankingEdit>
                                    <div [innerHTML]="msjRanking"></div>
                                </ng-template>
                                <a href="javascript:void(0)" [popover]="popTemplateRankingEdit" triggers="focus">
                                    <fa [name]="'question-circle-o'" name="question-circle"></fa>
                                </a>
                            </label>
                        </form-input>

                        <ng-container *ngIf="hasCarreras">
                            <embed-preferencia-universidades
                                id="academica_preferencia_universidades"
                                [form]="formInfoAcademica"
                                [amount]="preferenciaUniversidadesAmount"
                                [notRequired]="true"
                                [initialPreferenciaUniversidades]="usuarioEdit.preferenciaUniversidades"
                                (emitPreferencias)="updatePreferenciasUniversidades($event)"
                            ></embed-preferencia-universidades>

                            <!-- <div *ngFor="
                                    let preferenciaArea of usuarioEdit.preferenciaAreas;
                                    let indexArea = index;
                                    trackBy: trackByPreferenciaArea
                                ">
                                <form-input [id]="'academica_form_preferencia_areas_' + indexArea"
                                    [form]="formInfoAcademica" [params]="params['area_id']"
                                    [(value)]="preferenciaArea.areaId" (change)="updatePreferenciaArea($event)"
                                    [key]="'area_id'"></form-input>
                            </div> -->

                            <div *ngIf="usuarioEdit.preferenciaAreas.length > 0">
                                <form-input
                                    [id]="'academica_form_preferencia_areas_'"
                                    [form]="formInfoAcademica"
                                    [params]="params['area_id']"
                                    [(value)]="usuarioEdit.preferenciaAreas[0].areaId"
                                    (change)="updatePreferenciaArea($event)"
                                    [key]="'area_id'"
                                ></form-input>
                            </div>
                        </ng-container>

                        <div class="clearfix user-edit-buttons">
                            <cui-button [disabled]="saving" (click)="save(formInfoAcademica)" type="button" class="btn_style pull-right">
                                Guardar
                            </cui-button>
                            <cui-button
                                buttonType="cancel"
                                (click)="clear(formInfoAcademica)"
                                type="button"
                                class="btn_cancel pull-right"
                            >
                                Deshacer
                            </cui-button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <usuario-email-modal></usuario-email-modal>
</loading-layout>
