import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { GrupoUsuarios, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { AuthService } from "@puntaje/shared/core"
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"

@Component({
    templateUrl: "home_pruebas.component.html",
    styleUrls: ["home_pruebas.component.scss"]
})
export class HomePruebasComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []
    config: typeof config = config
    disableMenu: boolean = false

    constructor(
        protected store: Store<State>,
        protected grupoUsuariosService: GrupoUsuarios,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()
        this.setMenuItems()
        this.getGrupoUsuarios()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        // await this.asignaturaService.asignaturasPromise;

        this.menuItems = [
            {
                route: "/pruebas/resultados/",
                params: { tipo_instrumento: "curricular", asignatura_id: 1 },
                label: "Historial curricular",
                text: "Encuentra aquí la lista de todas las pruebas curriculares que has realizado.",
                linkText: "Ver historial",
                icon: "guia"
            },
            // {
            // 	route: '/pruebas/compartidas/',
            // 	params: {tipo_instrumento: 'curricular', asignatura_id: 1},
            // 	label: "Historial de pruebas compartidas",
            // 	text: "Encuentra aquí la lista de todas las pruebas curriculares que te han compartido.",
            // 	linkText: "Ver historial",
            // 	icon: "guia"
            // },
            {
                route: "/pruebas/mi_progreso/",
                params: { tipo_instrumento: "curricular" },
                label: "Mi Progreso curricular",
                text: "Observa la evolución en el tiempo de tus resultados obtenidos.",
                linkText: "Ver mi progreso",
                icon: "guia"
            },
            {
                route: "/pruebas/estadisticas/",
                params: { tipo_instrumento: "curricular" },
                label: "Estadísticas",
                text: "Encuentra aquí estadísticas generadas a partir de tus resultados.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }

    getGrupoUsuarios() {
        let usuario = this.authService.getUserData()
        let params = {
            establecimiento_id_not_nil: 1,
            propios: 1,
            grupo_usuario: {
                privado: 1
            },
            grupo_usuario_usuario: {
                propietario: 0,
                usuario_id: usuario.id
            }
        }

        this.grupoUsuariosService.where(params).then((grupoUsuarios: GrupoUsuario[]) => {
            this.disableMenu = grupoUsuarios.length <= 0
        })
    }
}
