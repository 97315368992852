<ll-titulo>Planes personales</ll-titulo>
<ll-subtitulo>
    {{ planPersonal.plan_personal_tipo.tipo }} {{ planPersonal.plan_personal }} -
    {{ planPersonal.generador_instrumento.asignatura.asignatura }}
</ll-subtitulo>

<loading-layout #planPersonalLoading>
    <plan-personal-sesiones
        *ngIf="planPersonal"
        [planPersonal]="planPersonal"
        [personal]="false"
    ></plan-personal-sesiones>
    <div class="btn-container text-center">
        <a class="btn btn-primary" routerLink="/planes_compartidos">Volver a Planes Compartidos</a>
    </div>
</loading-layout>
