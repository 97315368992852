import { Component, OnDestroy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Subscription } from "rxjs"
import { Asignatura } from "@puntaje/nebulosa/api-services"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "materiales_saber.component.html",
    styleUrls: ["materiales_saber.component.scss"]
})
export class MaterialesSaberComponent implements OnDestroy {
    asignatura: Asignatura
    cursoId: number = 1
    asignaturaId: number
    private sub: Subscription
    enableResponsive: boolean = false
    subData: Subscription
    titulo: string = "Materiales Saber"
    showBanner: boolean = true
    clasificacionEspecial: string = "saber"
    tipoCursos: string = "saber"
    tipoAsignaturas: string = "saber"

    constructor(protected titleService: TitleService, protected route: ActivatedRoute) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.materiales.materiales_saber")
        this.sub = this.route.queryParams.subscribe(query => {
            this.asignaturaId = +query["asignatura_id"]
        })
        this.subData = this.route.data.subscribe(data => {
            this.titulo = data.titulo ?? this.titulo
            this.showBanner = data.showBanner ?? this.showBanner
            this.clasificacionEspecial = data.clasificacionEspecial ?? this.clasificacionEspecial
            this.tipoCursos = data.tipoCursos ?? this.tipoCursos
            this.tipoAsignaturas = data.tipoAsignaturas ?? this.tipoAsignaturas
        })
        this.enableResponsive = window.innerWidth <= 900
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subData.unsubscribe()
    }

    onReady(asignatura) {
        this.asignatura = asignatura
    }

    onReadyCurso(curso_id) {
        this.cursoId = curso_id
    }

    onResize(event) {
        this.enableResponsive = event.target.innerWidth <= 900
    }
}
