import { Component, OnInit, Input } from "@angular/core"

@Component({
    selector: "reforzamientos-compartidos-alumnos",
    templateUrl: "./reforzamientos-compartidos-alumnos.component.html",
    styleUrls: ["./reforzamientos-compartidos-alumnos.component.scss"]
})
export class ReforzamientosCompartidosAlumnosComponent implements OnInit {
    @Input() planes

    constructor() {}

    ngOnInit() {}
}
