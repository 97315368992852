<!--<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container" style="background: #90d844">
                    <ogr-icon name="prueba" class="icon"></ogr-icon>
                </div>
            </div>
            <div class="showcasebox_title_text" style="color: #90d844">Ensayos Nueva Prueba de Transición</div>
        </div>
    </div>

    <div class="ensayos">
        <p class="ensayo">
            <span class="ensayo-link">
                <ogr-icon [name]="'matematica'" class="ogricon ensayo-icon"></ogr-icon>
                Competencias Matemáticas
            </span>
            <button [routerLink]="['/ensayos', 1000, 'realizar']" class="btn ensayo-btn">Comenzar</button>
        </p>
        <p class="ensayo">
            <span class="ensayo-link">
                <ogr-icon name="lenguaje" class="ogricon ensayo-icon lenguaje"></ogr-icon>
                Competencias Lectoras
            </span>
            <button [routerLink]="['/ensayos', 1003, 'realizar']" class="btn ensayo-btn">Comenzar</button>
        </p>
    </div>
</div>-->

<cui-showcasebox>
    <cui-showcasebox-title-icon>
        <ogr-icon name="prueba"></ogr-icon>
    </cui-showcasebox-title-icon>
    <cui-showcasebox-title-text>Ensayos PAES</cui-showcasebox-title-text>
    <cui-showcasebox-content>
        <div class="ensayos">
            <p class="ensayo">
                <span class="ensayo-link">
                    <ogr-icon [name]="'matematica'" class="ogricon ensayo-icon"></ogr-icon>
                    Competencias Matemáticas
                </span>
                <button [routerLink]="['/ensayos', 1000, 'realizar']" class="btn ensayo-btn">Comenzar</button>
            </p>
            <p class="ensayo">
                <span class="ensayo-link">
                    <ogr-icon name="lenguaje" class="ogricon ensayo-icon lenguaje"></ogr-icon>
                    Competencias Lectoras
                </span>
                <button [routerLink]="['/ensayos', 1003, 'realizar']" class="btn ensayo-btn">Comenzar</button>
            </p>
        </div>
    </cui-showcasebox-content>
</cui-showcasebox>
