import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { selectAsignaturasByEvaluacionTipo, State } from "@puntaje/puntaje/store"
import { BehaviorSubject, combineLatest, Subscription } from "rxjs"
import { filter } from "rxjs/operators"

@Component({
    selector: "menu-asignaturas-vertical",
    templateUrl: "menu_asignaturas_vertical.component.html",
    styleUrls: ["menu_asignaturas_vertical.component.scss"]
})
export class MenuAsignaturasVerticalComponent implements OnInit, OnChanges, OnDestroy {
    @Input() asignaturaId: number = 0
    @Input() clasificaciones: string[] = []
    @Input() enableResponsive: boolean = false
    @Input() tipoAsignaturas: string = ""
    @Input() tipoEvaluacion: string
    lista_asignaturas: any = []

    sub: Subscription
    evaluacionTipoSubject = new BehaviorSubject(null)
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(protected store: Store<State>) {}

    ngOnInit() {
        if (this.tipoEvaluacion) this.tipoEvaluacion = this.tipoAsignaturas

        this.sub = combineLatest(
            this.evaluacionTipoSubject.pipe(filter(x => !!x)),
            this.asignaturasByEvaluacionTipo$
        ).subscribe(([evaluacionTipo, asignaturasByEvaluacionTipo]) => {
            this.lista_asignaturas = asignaturasByEvaluacionTipo[evaluacionTipo || config.plataforma.evaluacionDefault]
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["tipoAsignaturas"] || changes["tipoEvaluacion"]) {
            this.evaluacionTipoSubject.next(this.tipoEvaluacion || this.tipoAsignaturas)
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
