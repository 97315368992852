import { Component, OnInit, Input } from "@angular/core"

@Component({
    selector: "tareas-compartidas-alumnos",
    templateUrl: "./tareas-compartidas-alumnos.component.html",
    styleUrls: ["./tareas-compartidas-alumnos.component.scss"]
})
export class TareasCompartidasAlumnosComponent implements OnInit {
    @Input() planes

    constructor() {}

    ngOnInit() {}
}
