<div class="showcasebox">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container"><ogr-icon name="entrenador-o" class="icon"></ogr-icon></div>
            </div>
            <!-- <div class="showcasebox_title_text">Entrenador virtual</div> -->
            <div class="showcasebox_title_text">{{ "entrenador.entrenador_showcasebox.titulo" | t }}</div>
        </div>
    </div>
    <div class="showcasebox_content">
        <cui-button-link
            [routerLinkValue]="['/entrenador/edit']"
            class="btn_style btn-config"
            size="small"
            [class.pending]="pendingConfig"
        >
            <fa name="cog"></fa>
            <!-- Configurar -->
            {{ "entrenador.entrenador_showcasebox.btn_config" | t }}
        </cui-button-link>
        <div class="content_frame" [class.pending-frame]="pendingConfig">
            <loading-layout #loadingLayout>
                <div *ngIf="!loadingLayout.loading">
                    <ng-container *ngIf="entrenadorUsuario?.entrenador_meta_id">
                        <div>
                            <h3>Meta semanal</h3>
                            <div class="clearfix">
                                <p class="progreso">
                                    {{ cantidadPreguntasActual }}/{{
                                        entrenadorUsuario.entrenador_meta.numero_preguntas
                                    }}
                                </p>
                                <p class="porcentaje">{{ progressValueActual | percentage }}</p>
                                <div class="dotted-line"></div>
                            </div>
                            <progressbar
                                class="progressbar"
                                [striped]="true"
                                [value]="animatedProgressValueActual"
                                [type]="'success'"
                            ></progressbar>
                        </div>
                        <div class="entrenador">
                            <div class="entrenador-row">
                                <div class="portrait">
                                    <img
                                        src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/pro/entrenador/portrait.png"
                                    />
                                </div>
                                <div class="speech-container">
                                    <p class="speechbubble">{{ encouragement }}</p>
                                </div>
                                <div class="meta-anterior-cell">
                                    <div class="meta-anterior">
                                        <p>
                                            <!-- Preguntas contestadas
                                            <br />
                                            la semana pasada: -->
                                            <span
                                                [innerHTML]="
                                                    'entrenador.entrenador_showcasebox.preguntas_contestadas'
                                                        | t
                                                        | trustedhtml
                                                "
                                            ></span>
                                            <b>{{ cantidadPreguntasAnterior }}</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <p *ngIf="pendingConfig" class="pending-config">
                        <!-- No tienes configurado tu entrenador virtual.
                        <br />
                        Configúralo
                        <a [routerLink]="['/entrenador/edit']">aquí</a>
                        . -->
                        <span [innerHTML]="'entrenador.entrenador_showcasebox.no_config' | t | trustedhtml"></span>
                        <a [routerLink]="['/entrenador/edit']">
                            {{ "entrenador.entrenador_showcasebox.link_config" | t }}
                        </a>
                    </p>
                </div>
            </loading-layout>
        </div>
    </div>
</div>
