<div class="material-thumbnail" *ngIf="material">
    <div class="thumbnail-wrap {{ thumbType }}" (click)="goToMaterial()">
        <div class="icono">
            <ogr-icon name="video" *ngIf="thumbType == 'video' && !thumbUrl"></ogr-icon>
            <img
                *ngIf="thumbUrl"
                [src]="thumbUrl"
                alt="video de youtube"
            />
            <ogr-icon name="archivo" *ngIf="thumbType == 'archivo' && !thumbUrl"></ogr-icon>
            <ogr-icon name="pdf" *ngIf="thumbType == 'pdf' && !thumbUrl"></ogr-icon>
        </div>
        <div class="overlay"></div>
    </div>
    <div class="descripcion-material">
        <label class="titulo">{{ material.material }}</label>
        <div class="asignatura" *ngIf="material.asignatura">Asignatura:{{ material.asignatura?.asignatura }}</div>
        <div class="fecha-subida">
            {{ "estudiar.streaming.materiales.fecha" | t }}&nbsp;{{ material.created_at | prettydate }}
        </div>

        <!--<ng-container *ngIf="enableMaterialRelacionado">
            <div *ngFor="let c of materialRelaciones">
                <cui-button-link *ngIf="c.relacion_type=='evaluacion'" (click)="goToEvaluacion(c.relacion_id)" class="  btn_style btn-sm pull-right">
                    Realizar prueba&nbsp;<span class="glyphicon glyphicon-arrow-right"></span>
                </cui-button-link>
                <cui-button-link *ngIf="c.relacion_type=='guia'" (click)="goToGuia(c.relacion_id)" class="  btn_style btn-sm pull-right">
                    Ver guia&nbsp;<span class="glyphicon glyphicon-arrow-right"></span>
                </cui-button-link>
            </div>
        </ng-container>-->
        <ul class="lista-material-relacionado" *ngIf="enableMaterialRelacionado">
            <ng-container *ngFor="let c of materialRelaciones">
                <li *ngIf="c.relacion_type == 'evaluacion'" (click)="goToEvaluacion(c.relacion_id)">
                    <div class="mr-icono">
                        <ogr-icon class="icono" name="editar-prueba"></ogr-icon>
                    </div>
                    <p>{{ "estudiar.streaming.materiales.tipo_evaluacion" | t }}</p>
                </li>
                <li *ngIf="c.relacion_type == 'guia'" (click)="goToGuia(c.relacion_id)">
                    <div class="mr-icono">
                        <ogr-icon class="icono" name="guia"></ogr-icon>
                    </div>
                    <p>{{ "estudiar.streaming.materiales.tipo_guia_estudio" | t }}</p>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="button-container">
        <cui-button-link href="javascript:void(0)" (click)="goToMaterial()" class="btn_style pull-right btn-sm">
            {{ "estudiar.streaming.materiales.ver" | t }}&nbsp;
            <span class="glyphicon glyphicon-arrow-right"></span>
        </cui-button-link>
        <cui-button-link
            *ngIf="isFileLink(material) && isArchivo"
            [attr.href]="getDownloadLink(material)"
            (click)="trackDownload()"
            download
            class="btn_style btn-sm pull-right"
        >
            <span class="glyphicon glyphicon-cloud-download"></span>
            &nbsp;Descargar
        </cui-button-link>
    </div>
</div>
