import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
    Output,
    Input,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
    ApplicationRef
} from "@angular/core"
import { Router } from "@angular/router"
import {
    Material,
    Materiales,
    Asignaturas,
    Asignatura,
    TipoMaterial,
    Clasificacion,
    Clasificaciones
} from "@puntaje/nebulosa/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { PaginatorComponent } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectAsignaturasList } from "@puntaje/puntaje/store"
import { Subscription } from "rxjs"
import { filter, last, first } from "rxjs/operators"
import { CursoSearchFilterComponent } from "./curso_search_filter.component"

@Component({
    selector: "materiales-with-thumbnails",
    templateUrl: "materiales_with_thumbnails.component.html",
    styleUrls: ["materiales_with_thumbnails.component.scss"]
})
export class MaterialesWithThumbnailsComponent implements OnInit, OnChanges {
    @Input() tracking: boolean = false
    asignatura: Asignatura
    params = Material.show_params
    paginatorContext: any
    tableButtonsArray: any
    reloadPaginator: boolean = true
    materiales: Material[]
    clasificaciones = {}
    materialQuery: string
    cursoQuery: string
    @Input() cursoId: number | number[]
    @Input() sortByDate: string
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(PaginatorComponent) paginator: PaginatorComponent
    @Input() enableMaterialRelacionado: boolean = false

    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() cursoSearch: boolean = false
    @Input() asignaturaId: number
    @Input() cursosId: number[]
    @Input() tipoMaterial: number
    @Input() enableSearch: boolean = true
    @Input() evaluacionTipo: string

    @Input() disablePlataforma: boolean = false
    @Input() enableModal: boolean = true

    clasificacionIds: number[] = []

    sub: Subscription
    asignaturas$ = this.store.pipe(
        select(selectAsignaturasList),
        filter(x => !!x)
    )
    showFilterClasificacionEvTipo: boolean

    constructor(
        protected store: Store<State>,
        protected router: Router,
        protected materialesService: Materiales,
        public cdr: ChangeDetectorRef,
        public appRef: ApplicationRef
    ) {}

    async ngOnInit() {
        let asignaturas = await this.asignaturas$.pipe(first()).toPromise()
        if (!this.asignaturaId) this.asignaturaId = asignaturas[0].id
        await this.setData(1, 12)
        this.setClasificaciones(this.materiales)
        this.showFilterClasificacionEvTipo = config.plataforma.hasOwnProperty("showFilterCET")
            ? config.plataforma.showFilterCET
            : true
    }

    setData(page: number, per: number) {
        this.loadingLayout.standby()
        this.cdr.detectChanges()
        let query: any = {
            material: {
                asignatura_id: this.asignaturaId,
                visible: 1
            },
            page: page,
            per: per
        }

        if (!this.disablePlataforma) {
            query.plataforma_id = config.plataforma.id
        }

        if (this.materialQuery) {
            query["material"]["like"] = { material: this.materialQuery }
        }
        if (this.tipoMaterial) {
            query["material"]["material_tipo_id"] = this.tipoMaterial
        }
        if (this.cursoId) {
            query["clasificacion"] = query["clasificacion"] || {}
            query["clasificacion"]["id"] = [this.cursoId]
        }

        if (this.cursosId) {
            query["clasificacion"] = query["clasificacion"] || {}
            query["clasificacion"]["id"] = [this.cursosId]
        }
        if (this.clasificacionIds.length > 0) {
            query["clasificacion"] = query["clasificacion"] || {}
            query["clasificacion"]["id"] = query["clasificacion"]["id"] || []
            query["clasificacion"]["id"] = query["clasificacion"]["id"].concat(this.clasificacionIds)

            query["and_clasificacion"] = 1
        }

        if (this.sortByDate) {
            query["sort_by"] = "created_at"
            query["order"] = this.sortByDate
        }

        return this.materialesService.where(query).then((response: Material[], total: number) => {
            this.materiales = null
            this.materiales = response
            this.setClasificaciones(this.materiales)
            this.loadingLayout.ready()

            return total
        })
    }

    updateSearch(currentSearch: string) {
        this.materialQuery = currentSearch
        this.buscar()
    }

    classificationSearch(searchParams) {
        this.materialQuery = searchParams["currentSearch"]
        this.cursoId = searchParams["classSearch"]
        this.buscar()
    }

    setClasificaciones(materiales) {
        this.clasificaciones = {}
        materiales.forEach(m => {
            m["clasificaciones"].forEach(c => {
                if (!Object.keys(this.clasificaciones).includes(c.id)) {
                    this.clasificaciones[c.id] = c
                }
            })
        })
    }

    buscar() {
        if (this.paginator) this.paginator.changePage(1)
        else this.setData(1, 12)
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["cursoId"] || changes["evaluacionTipo"]) {
            this.clasificacionIds = []

            this.loadingLayout.standby()
            this.cdr.detectChanges()
            window.setTimeout(() => {
                if (this.paginator) {
                    this.paginator.reload()
                }
            }, 0)
        }
    }

    onChangeFilter(clasificacionIds: number[]) {
        this.clasificacionIds = clasificacionIds

        if (this.paginator) {
            this.paginator.reload()
        }
    }

    setMateriales(materiales: Material[]) {
        this.materiales = materiales
    }

    bindData(binding) {
        this.paginatorContext = binding
        return this.setData.bind(binding)
    }
}
