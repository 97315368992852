<div *ngIf="carreras">
    <form-input
        *ngFor="let param of params; let i = index"
        [form]="form"
        [preload_options]="carreras"
        [params]="param"
        [key]="param.key"
        (valueChange)="setVals(i, $event)"
    ></form-input>
</div>
