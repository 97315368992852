<ll-titulo>{{ titulo }}</ll-titulo>
<ll-subtitulo>{{ asignatura?.asignatura }}</ll-subtitulo>

<materiales-dirigidos-menu
    *ngIf="enableMaterialesDirigidos"
    (onReady)="onReadyClasificaciones($event)"
    [enableEjercitacion]="false"
    [enableProfesores]="enableProfesores"
></materiales-dirigidos-menu>
<materiales-cursos (onReady)="onReadyCurso($event)" [tipoCursos]="tipoCursos"></materiales-cursos>
<div (window:resize)="onResize($event)" [class.enableResponsive]="enableResponsive">
    <div class="left_tabs">
        <menu-asignaturas-vertical
            [asignaturaId]="asignaturaId"
            [enableResponsive]="enableResponsive"
            [tipoAsignaturas]="tipoAsignaturas"
        ></menu-asignaturas-vertical>
    </div>
    <div class="tabs_content">
        <materiales
            [asignaturaId]="asignaturaId"
            [cursoId]="cursoId"
            [clasificaciones]="clasificaciones"
            [showMaterialVisible]="showMaterialVisible"
            (onReady)="onReady($event)"
        ></materiales>
    </div>
</div>
