import { Component, Directive, ViewChild, AfterContentInit, Input, ChangeDetectorRef, TemplateRef } from "@angular/core"
import { AuthService } from "@puntaje/shared/core"
import { queueScheduler, Subscription } from "rxjs"
import { observeOn } from "rxjs/operators"
import { RestrictedLayoutService } from "./restricted-layout.service"

/*#################################

restricted-layout-toolnavbar
restricted-layout-titulo
restricted-layout-subtitulo
restricted-layout-contenido
restricted-layout-tabs
restricted-layout-topside

#################################*/

@Component({
    templateUrl: "cui-restricted-layout.component.html",
    selector: "cui-restricted-layout",
    styleUrls: ["cui-restricted-layout.component.scss"]
})
export class CUIRestrictedLayoutComponent {
    @ViewChild("restrictedLayoutContenido") restrictedLayoutContenido
    showTabs: boolean = false
    showContenido: boolean = false
    showTopside: boolean = false

    @Input() freeContentStyle: boolean = false

    sub: Subscription
    subTemplate: Subscription

    templateObj: { [name: string]: TemplateRef<any> } = {}

    constructor(
        public authService: AuthService,
        protected cdr: ChangeDetectorRef,
        protected restrictedLayoutService: RestrictedLayoutService
    ) {}

    ngOnInit(): void {
        this.restrictedLayoutService.resetSlots()
        this.restrictedLayoutService.setOptions({
            freeContentStyle: this.freeContentStyle
        })

        this.sub = this.restrictedLayoutService.options$.subscribe(options => {
            this.freeContentStyle = options.freeContentStyle
        })

        this.subTemplate = this.restrictedLayoutService.templates$
            .pipe(observeOn(queueScheduler))
            .subscribe(templates => {
                this.templateObj = templates

                this.checkSlots()
                this.cdr.detectChanges()
            })
    }

    checkSlots() {
        this.showTabs = !!this.templateObj?.["tabs"]
        this.showContenido = true
        this.showTopside = !!this.templateObj?.["topside"]
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }
}
