import { Component, OnInit } from "@angular/core"

@Component({
    selector: "ensayos-showcase",
    templateUrl: "./ensayos-showcase.component.html",
    styleUrls: ["./ensayos-showcase.component.scss"]
})
export class EnsayosShowcaseComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
