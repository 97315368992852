import { Component, OnInit, ViewChild, Output, Input, EventEmitter, SimpleChanges, OnChanges } from "@angular/core"
import { Router } from "@angular/router"
import { Material, Materiales, Asignaturas, Asignatura, Clasificacion } from "@puntaje/nebulosa/api-services"
import { MaterialPreviewModalComponent } from "./modals/material_preview_modal.component"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "materiales",
    templateUrl: "materiales.component.html",
    styleUrls: ["materiales.component.scss"]
})
export class MaterialesComponent implements OnInit, OnChanges {
    asignatura: Asignatura
    params = Material.show_params
    tableButtonsArray: any
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild(MaterialPreviewModalComponent, { static: true }) modalMaterial: MaterialPreviewModalComponent
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    @Input() asignaturaId: number
    @Input() clasificaciones: string[]
    @Input() cursoId: number
    @Input() showMaterialVisible?: boolean
    @Input() clasificacionEspecial: string
    config: typeof config = config
    clasificacionesRearranged: Clasificacion[]
    contadorMateriales: any[]

    constructor(protected asignaturasService: Asignaturas, protected router: Router) {}

    ngOnInit() {
        this.loadingLayout.standby()
    }

    showAll() {
        this.loadingLayout.standby()
        const queryParams = { with_clasificaciones_with_materiales: 1 }
        if (this.cursoId) {
            queryParams["curso_id"] = this.cursoId
        }
        if (this.showMaterialVisible) {
            queryParams["material_visible"] = this.showMaterialVisible
        }
        if (config.evaluaciones[config.plataforma.evaluacionDefault].clasificacionMaterial.clasificacion) {
            queryParams["clasificacion_tipo"] =
                config.evaluaciones[config.plataforma.evaluacionDefault].clasificacionMaterial.clasificacion
        }
        if (this.clasificaciones) {
            queryParams["clasificacion"] = { clasificacion: this.clasificaciones }
        }
        this.asignaturasService.find(this.asignaturaId, queryParams).then((response: Asignatura) => {
            this.asignatura = response
            this.changeFormat()
            this.onReady.emit(this.asignatura)
            this.loadingLayout.ready()
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["asignaturaId"] || changes["cursoId"] || changes["clasificaciones"]) {
            this.showAll()
        }
    }

    changeFormat() {
        this.clasificacionesRearranged = Object.assign([], this.asignatura.clasificaciones)
        this.clasificacionesRearranged.forEach(c => {
            // crear un objeto de materiales
            let materialesRearranged = {}
            // guardar los materiales que vienen en el padre, con los ids como llaves
            materialesRearranged = {}
            c.materiales_propios.forEach(m => {
                materialesRearranged[m.id] = m
            })
            // recorrer todas las clasificaciones hijas
            c.clasificaciones_hijas.forEach(ch => {
                // agregar esos materiales a la lista de materiales con la clasificacion
                ch.materiales.forEach(m => {
                    if (!materialesRearranged[m.id]) {
                        materialesRearranged[m.id] = m
                    }
                    materialesRearranged[m.id].clasificaciones.push(ch)
                })
            })
            // reemplazar la lista de materiales con el objeto nuevo de materiales
            c.materiales_propios = Object.keys(materialesRearranged)
                .map((k, i) => materialesRearranged[k])
                .filter(m => m.visible)
        })
        this.setCounts()
    }

    setCounts() {
        this.contadorMateriales = []
        this.clasificacionesRearranged.forEach((c, index) => {
            this.contadorMateriales[index] = {
                total: c.materiales_propios.length
            }
        })
    }

    openMaterialPreview(material) {
        this.modalMaterial.open(material)
    }
}
