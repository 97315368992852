<ll-titulo>Pruebas curriculares</ll-titulo>

<div class="about-section clearfix">
    <ogr-icon name="establecimiento-o" class="icono"></ogr-icon>
    <p>
        Si tu colegio tiene contratado los servicios de {{ config.plataforma.info.companyName }}, en esta sección
        encontrarás las pruebas curriculares que te han compartido tus profesores. Contarás también con tu historial de
        pruebas curriculares, estadísticas, y sección de progreso curricular. Los colegios pueden conocer los productos
        y tarifas y contratar el servicio enviando un correo a:
        <b>{{ config.plataforma.info.contacto.mail }}</b>
    </p>
</div>
<menu-grid-layout [menuItems]="menuItems" [class.disable-options]="disableMenu"></menu-grid-layout>
