import { BaseModel, Validations, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { UsuarioChile } from "./usuario_chile.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { PreferenciaCarrera } from "./preferencia_carreras.model"
import { UsuarioRegistroBase } from "./usuarios_registro_base.model"

export class UsuarioRegistroUtem extends UsuarioRegistroBase {
    public nombre: string
    public apellido_paterno: string
    public apellido_materno: string
    public email: string
    public fecha_nacimiento: Date
    public password: string
    public password_confirmation: string

    public egresado: number
    public genero: number
    public lugar_id: number
    public establecimiento_id: number
    public rut: string
    public telefono: string
    public celular: string
    public codigo_etnia: number
    public direccion: string
    public nivel_id: number
    public typoEnvio: string
    public instagram: string

    public preferencias_carrera: PreferenciaCarrera[] = []

    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => Establecimiento) public colegio: Establecimiento

    public static global_validations = [Validations.equalPasswords(["password", "password_confirmation"])]

    constructor() {
        super()
        this.lugar = new Lugar()
        this.colegio = new Establecimiento()
    }

    public nombreCompleto() {
        return this.nombre + " " + this.apellido_paterno + " " + this.apellido_materno
    }

    public toString() {
        return this.email
    }

    public setPreferenciaCarrera(preferencias) {
        this.preferencias_carrera = []
        for (let preferencia of preferencias) {
            let p = new PreferenciaCarrera()
            p.carrera_id = preferencia.id
            p.orden = preferencia.orden
            this.preferencias_carrera.push(p)
        }
    }

    public toUsuario() {
        var user = new Usuario()
        user.nombre = this.nombre
        user.apellido_paterno = this.apellido_paterno
        user.apellido_materno = this.apellido_materno
        user.fecha_nacimiento = this.fecha_nacimiento
        user.email = this.email
        user.password = this.password
        user.password_confirmation = this.password_confirmation
        user.tipo_envio = this.typoEnvio
        user.instagram = this.instagram
        if (this.lugar && this.lugar.id) {
            user.lugar_id = this.lugar.id
        }
        if (this.colegio && !this.egresado) {
            user.establecimiento_id = this.colegio.id
        }
        if (this.nivel_id) {
            user.nivel_id = this.nivel_id
        }
        user.pais_id = 1 // Generalizar
        user.rol_id = 3 // Generalizar, debería siempre ser 3 (alumno)
        user.usuario_colombia = null
        user.genero = this.genero
        if (!user.usuario_chile) {
            user.usuario_chile = new UsuarioChile()
        }
        user.usuario_chile.rut = this.rut
        user.usuario_chile.telefono = this.telefono
        user.usuario_chile.celular = this.celular
        user.usuario_chile.codigo_etnia = this.codigo_etnia
        user.usuario_chile.direccion = this.direccion
        user.preferencia_carreras = this.preferencias_carrera
        return user
    }
}
