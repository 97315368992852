<div class="login-box">
    <ng-container *ngIf="!isLoggedIn()">
        <form novalidate [formGroup]="form" (ngSubmit)="login()">
            <div class="mensaje-error" *ngIf="invalidLogin">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
            </div>
            <h2 class="login-title">Iniciar sesión</h2>
            <div class="login-inputs">
                <div class="input-wrap">
                    <div class="form-group">
                        <fa class="icono" name="user"></fa>
                        <input
                            type="text"
                            name="email"
                            [(ngModel)]="email"
                            [placeholder]="config.mensajesLogin.idPlaceholder"
                            *ngIf="!isLoggedIn()"
                            class="form-control"
                            formControlName="email"
                        />
                        <div class="mensaje-error-input" *ngIf="checkEmail()">
                            <p>{{ config.mensajesLogin.invalidLoginId }}</p>
                        </div>
                    </div>
                    <div class="form-group">
                        <fa class="icono" name="key"></fa>
                        <input
                            type="password"
                            name="password"
                            [(ngModel)]="password"
                            placeholder="Contraseña"
                            *ngIf="!isLoggedIn()"
                            class="form-control"
                            formControlName="password"
                        />
                        <div class="mensaje-error-input" *ngIf="checkPassword()">
                            <p>Debes incluir una contraseña.</p>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn_base_1 btn-ingresar" *ngIf="!isLoggedIn()">
                    <fa name="arrow-right"></fa>
                </button>
            </div>

            <div class="bottom-options">
                <p class="registro">
                    <a type="button" class="registro" (click)="goToRegister()">Regístrate</a>
                </p>
                <div class="pwd-reset" *ngIf="enablePasswordRecovery">
                    <p>
                        <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">
                            ¿Olvidaste tu contraseña?
                        </a>
                    </p>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="isLoggedIn()">
        <div class="loggedIn-box">
            <div class="row">
                <div class="col-md-6">
                    <cui-button-link [isBlock]="true" class="btn_style btn-home" routerLink="/home">
                        <ogr-icon name="home-o" align="text-top" class="icono"></ogr-icon>
                        &nbsp;Home
                    </cui-button-link>
                </div>
                <div class="col-md-6">
                    <cui-button [isBlock]="true" buttonType="cancel" (click)="logout()" class="btn_style_2">
                        Cerrar sesión
                    </cui-button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<request-reset-password></request-reset-password>
