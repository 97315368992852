import { Component, OnInit, ViewChild } from "@angular/core"
import { EntrenadorUsuarios, EntrenadorUsuario, EntrenadorMeta, EntrenadorMetas } from "@puntaje/puntaje/api-services"
import { AuthService, CheckAchievementAsync } from "@puntaje/shared/core"
import { Router } from "@angular/router"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"
@Component({
    selector: "entrenador-usuarios-edit",
    templateUrl: "./entrenador_usuarios_edit.component.html",
    styleUrls: ["./entrenador_usuarios_edit.component.scss"]
})
export class EntrenadorUsuariosEditComponent implements OnInit {
    entrenadorUsuario: EntrenadorUsuario
    entrenadorMetas: EntrenadorMeta[]
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    btnSaveDisabled: boolean = true
    displaySelectTrainer: boolean = false

    constructor(
        protected entrenadorUsuariosService: EntrenadorUsuarios,
        protected authService: AuthService,
        protected entrenadorMetasService: EntrenadorMetas,
        protected router: Router,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        let params = {
            entrenador_usuario: {
                usuario_id: this.authService.getUserData().id
            }
        }

        this.entrenadorUsuariosService.where(params).then((entrenadorUsuarios: EntrenadorUsuario[]) => {
            if (!entrenadorUsuarios[0]) {
                let entrenadorUsuario = new EntrenadorUsuario()
                entrenadorUsuario.usuario_id = this.authService.getUserData().id

                this.entrenadorUsuariosService.save(entrenadorUsuario).then((eu: EntrenadorUsuario) => {
                    this.entrenadorUsuario = eu
                })
            } else {
                this.entrenadorUsuario = entrenadorUsuarios[0]
            }
        })

        this.entrenadorMetasService.where().then((entrenadorMetas: EntrenadorMeta[]) => {
            this.entrenadorMetas = entrenadorMetas
            this.loadingLayout.ready()
        })
    }

    updateSaveBtn() {
        this.btnSaveDisabled = false
    }

    updateMeta(meta_id: number) {
        this.entrenadorUsuario.entrenador_meta_id = meta_id
        this.btnSaveDisabled = false
    }

    @CheckAchievementAsync("PN_ENTRENADOR_CONF_META", CrearAlertaLogros)
    save() {
        this.btnSaveDisabled = true
        return this.entrenadorUsuariosService
            .update(this.entrenadorUsuario.id, this.entrenadorUsuario)
            .then((entrenadorUsuario: EntrenadorUsuario) => {
                this.router.navigate(["home"])

                return entrenadorUsuario
            })
    }

    toggleChangeTrainer(value: boolean) {
        this.displaySelectTrainer = value
    }

    selectTrainer() {}
}
