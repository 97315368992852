import { BaseModel, SaveType } from "@puntaje/shared/core"
import { Usuario } from "./usuarios.model"
import { Lugar } from "./lugares.model"
import { Establecimiento } from "./establecimientos.model"
import { UsuarioChile } from "./usuario_chile.model"
import { UsuarioEdit } from "./usuarios_edit.model"
import { PreferenciaArea, PreferenciaUniversidad } from "@puntaje/carreras/api-services"

export class UsuarioEditPuntaje extends UsuarioEdit {
    public nem: number
    public ranking: number
    public usuario_chile_id: number
    public telefono: string
    public preferenciaAreas: PreferenciaArea[] = []
    public preferenciaUniversidades: PreferenciaUniversidad[] = []

    @SaveType(() => Lugar) public lugar: Lugar
    @SaveType(() => Usuario) public usuario: Usuario
    @SaveType(() => Establecimiento) public establecimiento: Establecimiento

    constructor(id: number) {
        super(id)

        this.usuario.usuario_chile = new UsuarioChile()
    }

    public fromUsuario(usuario: Usuario) {
        super.fromUsuario(usuario)

        if (usuario.usuario_chile) {
            this.usuario_chile_id = usuario.usuario_chile.id

            this.telefono = usuario.usuario_chile.telefono
            this.nem = usuario.usuario_chile.nem
            this.ranking = usuario.usuario_chile.ranking
        }
    }

    public toUsuario() {
        super.toUsuario()

        this.usuario.usuario_chile.id = this.usuario_chile_id
        this.usuario.usuario_chile.telefono = this.telefono
        this.usuario.usuario_chile.nem = this.nem
        this.usuario.usuario_chile.ranking = this.ranking
        this.usuario.preferencia_areas = this.preferenciaAreas
        this.usuario.preferencia_universidades = this.preferenciaUniversidades

        return this.usuario
    }
}
