<ll-titulo>Planes Compartidos</ll-titulo>
<ll-tabs>
    <ul class="nav nav-tabs print-hide" #tabList>
        <li role="presentation" [ngClass]="setTabClasses('tareas')">
            <a data-toggle="tab" href="#tareas" (click)="openingTab = 'tareas'">Tareas</a>
        </li>
        <li role="presentation" [ngClass]="setTabClasses('reforzamientos')">
            <a data-toggle="tab" href="#reforzamientos" (click)="openingTab = 'reforzamientos'">Reforzamientos</a>
        </li>
    </ul>
</ll-tabs>
<menu-evaluacion-tipo-asignaturas [showTitleOverMenu]="false" [diagnostico]="true"></menu-evaluacion-tipo-asignaturas>
<loading-layout #loadingLayout>
    <div class="tab-content">
        <div id="tareas" class="tab-pane" [ngClass]="setTabClasses('tareas')">
            <tareas-compartidas-alumnos [planes]="tareas"></tareas-compartidas-alumnos>
        </div>
        <div id="reforzamientos" class="tab-pane" [ngClass]="setTabClasses('reforzamientos')">
            <reforzamientos-compartidos-alumnos [planes]="reforzamientos"></reforzamientos-compartidos-alumnos>
        </div>
    </div>
</loading-layout>
