<div class="row">
    <div class="col-md-4 info-section">
        <p>
            {{ "entrenador.entrenador_usuarios_edit.descripcion" | t }}
        </p>
        <p>{{ "entrenador.entrenador_usuarios_edit.descripcion_dos" | t }}</p>
        <!--<p>También puedes escoger al Entrenador Virtual que te acompañará en tus desafíos.</p>-->
    </div>
    <div class="col-md-4">
        <cui-section-headline>{{ "entrenador.entrenador_usuarios_edit.semanal" | t }}</cui-section-headline>
        <loading-layout #loadingLayout>
            <label class="meta-label">{{ "entrenador.entrenador_usuarios_edit.meta_actual" | t }}:</label>
            <table class="table opciones-metas" *ngIf="entrenadorUsuario">
                <tbody>
                    <tr
                        *ngFor="let entrenadorMeta of entrenadorMetas"
                        [class.selected]="entrenadorMeta.id == entrenadorUsuario.entrenador_meta_id"
                        (click)="updateMeta(entrenadorMeta.id)"
                    >
                        <td class="min-width">
                            <span
                                class="circle-select"
                                [class.selected]="entrenadorMeta.id == entrenadorUsuario.entrenador_meta_id"
                            >
                                <ogr-icon
                                    name="ticket"
                                    class="icono"
                                    *ngIf="entrenadorMeta.id == entrenadorUsuario.entrenador_meta_id"
                                ></ogr-icon>
                            </span>
                        </td>
                        <td>
                            <b>{{ entrenadorMeta.entrenador_nombre_meta }}</b>
                        </td>
                        <td>{{ entrenadorMeta.entrenador_meta }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="btn-container clearfix">
                <cui-button
                    (click)="save()"
                    [disabled]="btnSaveDisabled"
                    class="btn_style pull-right"
                    [class.btn_style_2]="btnSaveDisabled"
                    [class.btn_style]="!btnSaveDisabled"
                >
                    {{ "entrenador.entrenador_usuarios_edit.guardar" | t }}
                </cui-button>
            </div>
        </loading-layout>
    </div>
    <div class="col-md-4">
        <cui-section-headline>{{ "entrenador.entrenador_usuarios_edit.entrenador_virtual" | t }}</cui-section-headline>
        <div class="trainer-container" *ngIf="!displaySelectTrainer">
            <!--<cui-button type="button" (click)="toggleChangeTrainer(true)" class="  btn_style pull-right">Cambiar</button>-->
            <div class="portrait">
                <img
                    src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/puntaje/pro/entrenador/profile.png"
                />
            </div>
        </div>
        <div class="trainer-select" *ngIf="displaySelectTrainer">
            <div class="clearfix">
                <p class="select-text">{{ entrenador.entrenador_usuarios_edit.selecciona_entreador" | t }}</p>
                <button
                    type="button"
                    class="btn btn-default btn_style_2 pull-right"
                    (click)="toggleChangeTrainer(false)"
                >
                    {{ "entrenador.entrenador_usuarios_edit.cancelar" | t }}
                </button>
            </div>
            <div class="container-options clearfix">
                <div class="portrait-select selected" (click)="selectTrainer()">
                    <div class="selected-markup"><ogr-icon name="ticket" class="icono"></ogr-icon></div>
                    <img src="https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/shared/default-profile.png" />
                </div>
                <div class="portrait-select"></div>
                <div class="portrait-select"></div>
            </div>
        </div>
    </div>
</div>
