<loading-layout #streamingLoading>
    <div *ngIf="channelAvailable() && streaming && streaming.streaming_enabled">
        <!-- streaming online-->
        <div class="row">
            <div class="col-md-12 col-lg-8 col-sm-12">
                <div class="row">
                    <div class="col-md-12">
                        <streaming-embedded-video [channel_id]="canal"></streaming-embedded-video>
                    </div>
                </div>
                <div *ngIf="streaming.material" class="row">
                    <div class="col-md-12 material-relacionado">
                        <cui-section-headline>{{ "estudiar.streaming.relacionado" | t }}</cui-section-headline>
                        <ogr-icon class="icono" name="guia"></ogr-icon>
                        <a [attr.href]="streaming.material.url" target="_blank">{{ streaming.material }}</a>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-sm-12">
                {{ videoId }}
                <streaming-embedded-chat *ngIf="videoId" [video_id]="videoId"></streaming-embedded-chat>
            </div>
        </div>
    </div>
    <div
        [class.row]="config.banners?.streaming?.main"
        *ngIf="!streaming || (streaming && !streaming.streaming_enabled)"
    >
        <!-- streamin offline -->
        <!-- Si hay una imagen para stream offline -->
        <div *ngIf="config.app.assets.streamingOffline" [class.col-md-8]="config.banners?.streaming?.main">
            <img src="{{ config.app.assets.streamingOffline }}" alt="Streaming Offline" />
        </div>
        <!-- Si no hay imagen -->
        <div *ngIf="!config.app.assets.streamingOffline" [class.col-md-8]="config.banners?.streaming?.main">
            <!-- Checkea si existe un canal en la config -->
            <div *ngIf="channelAvailable()" class="offline-message clearfix">
                <div class="offline-message-row">
                    <div class="offline-icon">
                        <ogr-icon name="camara-video" class="icono"></ogr-icon>
                    </div>
                    <div class="offline-text">
                        {{ "estudiar.streaming.offline" | t }}
                        <span *ngIf="canal">
                            <span
                                [innerHTML]="'estudiar.streaming.visitar_canal' | t: { canal: canal } | trustedhtml"
                            ></span>
                            .
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="!channelAvailable()" class="offline-message clearfix">
                <div class="offline-message-row">
                    <div class="offline-icon">
                        <ogr-icon name="camara-video"></ogr-icon>
                    </div>
                    <div class="offline-text">{{ "estudiar.streaming.sin_canal" | t }}</div>
                </div>
            </div>
        </div>
        <!-- Checkea si las zonas del arte estan habilitadas -->
        <div *ngIf="config.banners?.streaming?.main" [class.col-md-4]="config.banners?.streaming?.main">
            <banner-show
                [pagina]="config.banners?.streaming?.main?.banner.pagina"
                [zona]="config.banners?.streaming?.main?.banner.pagina.zona"
                [bannerTipo]="config.banners?.streaming?.main?.banner.pagina.bannerTipo"
            ></banner-show>
        </div>
    </div>
</loading-layout>
