<div class="content-container">
    <div class="row">
        <div class="col-sm-12">
            <div class="container-fluid bottom-padding with-max-width responsive-no-padding">
                <div class="clearfix" [class.content-header-with-topside]="showTopside">
                    <cui-titlebar [noSocialLinks]="true">
                        <cui-titlebar-titulo>
                            <l-slot name="titulo"></l-slot>
                        </cui-titlebar-titulo>
                        <cui-titlebar-subtitulo>
                            <l-slot name="subtitulo"></l-slot>
                        </cui-titlebar-subtitulo>
                    </cui-titlebar>
                    <div [class.logged-layout-topside]="showTopside" #restrictedLayoutTopside>
                        <l-slot name="topside"></l-slot>
                    </div>
                </div>
                <div [class.logged-layout-tabs]="showTabs" #restrictedLayoutTabs>
                    <l-slot name="tabs"></l-slot>
                </div>
                <div
                    [class.logged-layout-contenido]="showContenido && !freeContentStyle"
                    [class.with-tabs]="showTabs"
                    [class.free-logged-layout-contenido]="freeContentStyle"
                    #restrictedLayoutContenido
                >
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
    <div class="push-footer"></div>
</div>
<ng-content select="cui-layout-footer"></ng-content>
