import { Component, OnInit, Input, Optional } from "@angular/core"
import { ActivatedRoute, Params } from "@angular/router"
import { select, Store } from "@ngrx/store"
import { Clasificaciones, TipoMaterial } from "@puntaje/nebulosa/api-services"
import { selectSelectedAsignatura, State } from "@puntaje/puntaje/store"
import { AppConfig } from "@puntaje/shared/core"
import { Subscription } from "rxjs"
import { filter } from "rxjs/operators"
declare const config: AppConfig

@Component({
    templateUrl: "streaming_videos_list.component.html",
    selector: "streaming-videos-list"
})
export class StreamingVideosListComponent implements OnInit {
    @Input() tracking: boolean = false
    asignatura_id: number
    tipoMaterial: number
    plataforma_id: number
    tipoAsignatura: string
    @Input() tipoStreaming: string
    @Input() enableMaterialRelacionado: boolean = false
    cursos = config.plataforma?.cursosStreaming
    cursosId = this.cursos ? this.cursos.map(c => c.id) : null
    cursosLoaded = false

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    sub: Subscription

    constructor(
        private route: ActivatedRoute,
        private clasificacionesService: Clasificaciones,
        protected store: Store<State>
    ) {}

    async ngOnInit() {
        this.plataforma_id = config.plataforma.id
        this.tipoAsignatura = this.tipoStreaming || config.plataforma.evaluacionDefault
        this.tipoMaterial = TipoMaterial.STREAMING

        if (this.cursosId && this.cursosId.every(c => !c)) {
            const params = {
                clasificacion: {
                    clasificacion: this.cursos.map(c => c.clasificacion)
                },
                clasificacion_tipo: {
                    clasificacion_tipo: config.plataforma.clasificacionTipoNivel
                }
            }

            const clasificaciones = await this.clasificacionesService.where(params)

            this.cursosId = this.cursos
                .map(c => {
                    const cursoId = clasificaciones.find(
                        clasificacion => clasificacion.clasificacion == c.clasificacion
                    )?.id

                    return cursoId
                })
                .filter(x => !!x)

            this.cursosLoaded = true
        } else {
            this.cursosLoaded = true
        }

        this.sub = this.asignatura$.subscribe(asignatura => {
            this.asignatura_id = asignatura.id
        })
    }
}
