<ll-over-title>
    <arte-home-showcasebox-barra [pagina]="pagina"></arte-home-showcasebox-barra>
</ll-over-title>
<ll-titulo>Bienvenid@</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <notificaciones-showcasebox
            [maximoNotificaciones]="3"
            [enableContentStyle]="false"
        ></notificaciones-showcasebox>
        <entrenador-showcasebox></entrenador-showcasebox>
        <planes-estudios-showcasebox
            [maxColumns]="1"
            [asignaturasBoxInsteadPlanClase]="true"
        ></planes-estudios-showcasebox>
    </div>
    <div class="col-md-4 col-sm-6">
        <dudas-showcase></dudas-showcase>
    </div>
    <div class="col-md-4 col-sm-6">
        <ensayos-showcase></ensayos-showcase>
        <arte-home-showcasebox [pagina]="pagina"></arte-home-showcasebox>
    </div>
</div>
<arte-zona-modal [pagina]="pagina"></arte-zona-modal>
