import { BaseForm, Validations, DateHelper } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { Areas } from "@puntaje/carreras/api-services"

export class UsuarioEditInfoAcademicaPuntajeForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "id", type: "hidden", visible: false },
            establecimiento_id: { label: "Establecimiento", type: "text", visible: true },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: { class: Clasificaciones, params: { clasificacion_tipo: { clasificacion_tipo: "curso" } } },
                validations: [Validators.required]
            },
            area_id: {
                label: "Área de interés",
                type: "select",
                visible: true,
                options: { class: Areas, params: {} }
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            asignatura_id: { label: "Asignatura", type: "hidden", visible: false },
            nem: {
                label: "Puntaje NEM",
                type: "number",
                visible: true,
                validations: [Validators.min(100), Validators.max(1000)]
            },
            ranking: {
                label: "Puntaje Ranking",
                type: "number",
                visible: true,
                validations: [Validators.min(100), Validators.max(1000)]
            },
            profesor: {
                label: "Soy...",
                type: "radio",
                visible: true,
                validations: [Validators.required],
                options: [
                    {
                        id: false,
                        toString: function () {
                            return "Estudiante"
                        }
                    },
                    {
                        id: true,
                        toString: function () {
                            return "Profesor/a"
                        }
                    }
                ]
            }
        }
    }
}
