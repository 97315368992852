import { Component, Input, OnInit } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { DomSanitizer } from "@angular/platform-browser"
import { Streaming, Streamings } from "@puntaje/puntaje/api-services"

@Component({
    selector: "streaming-last",
    templateUrl: "streaming_last.component.html",
    styleUrls: ["streaming_last.component.scss"]
})
export class StreamingLastComponent implements OnInit {
    plataforma_id: number
    video_url: string
    url: any
    title: string
    config: typeof config
    description: string
    width: number = 640
    height: number = 480

    last_video: string
    streaming: Streaming
    @Input() tipoStreaming: string
    @Input() channelAliasType: string
    @Input() channelAliasKey: string

    constructor(private streamingService: Streamings, private sanitizer: DomSanitizer) {
        this.config = config
    }

    ngOnInit() {
        if (this.tipoStreaming) {
            const stream = this.getChannel()
            if (stream) {
                this.streamingService
                    .where({ streaming: { channel_alias: stream } })
                    .then((streamings: Streaming[]) => {
                        if (streamings.length > 0) {
                            this.streaming = streamings[0]
                            this.url = "https://www.youtube.com/embed/" + this.streaming.last_video
                            this.video_url = this.sanitizeLink(this.url)
                            this.title = this.streaming.title
                            this.description = this.streaming.description
                            this.last_video = this.streaming.last_video
                        }
                    })
            } else {
                this.video_url = ""
            }
        } else {
            this.video_url = ""
        }
    }

    getChannel() {
        if (this.channelAliasType && this.channelAliasKey) {
            switch (this.channelAliasType) {
                case "nivel":
                    return this.config.evaluaciones[this.tipoStreaming].streamingChannelAliasByNivel[
                        this.channelAliasKey
                    ]
                default:
                    return undefined
            }
        } else {
            return this.config.evaluaciones[this.tipoStreaming].streamingChannelAlias
        }
    }

    sanitizeLink(uri): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(uri)
    }
}
