import { CarreraSedeComponent, MisCarrerasComponent } from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { RouterModule, Routes } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "mis-carreras",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MisCarrerasComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    withPais: false,
                    withTerminosCarreras: false
                }
            }
        ]
    },
    {
        path: "carrera_sedes/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CarreraSedeComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
