import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    OnChanges,
    SimpleChanges
} from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"

import { Streaming } from "@puntaje/puntaje/api-services"

@Component({
    selector: "streaming-embedded-video",
    templateUrl: "streaming_embedded_video.component.html",
    styleUrls: ["streaming_embedded_video.component.scss"]
})
export class StreamingEmbeddedVideoComponent implements OnChanges {
    url: SafeResourceUrl

    @Input() width = 640
    @Input() height = 480
    @Input() max_width = 751
    @Input() max_height = 480
    @Input() channel_id: string
    @Input() autoplay = true

    @ViewChild("streamIframe") streamIframe: ElementRef

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes["channel_id"]) {
            this.url = this.getStreamUrl()
        }
    }

    getStreamUrl() {
        let url = Streaming.getStreamingUrl(this.channel_id)
        if (this.autoplay) {
            url = url + "&autoplay=1"
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }

    setRatioSize() {
        if (this.streamIframe) {
            if (this.streamIframe.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.streamIframe.nativeElement.offsetWidth
            }
        }
        this.height = (this.width * this.max_height) / this.max_width
    }

    onResize(event) {
        this.setRatioSize()
    }

    onLoadIframe() {
        this.setRatioSize()
    }
}
