import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"

import { Streaming } from "@puntaje/puntaje/api-services"

import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "streaming-embedded-chat",
    templateUrl: "streaming_embedded_chat.component.html",
    styleUrls: ["streaming_embedded_chat.component.scss"]
})
export class StreamingEmbeddedChatComponent implements OnInit {
    url: SafeResourceUrl
    @Input() width = 640
    @Input() height = 480
    @Input() max_width = 640
    @Input() max_height = 480
    @Input() video_id: string

    @ViewChild("chatIframe") chatIframe: ElementRef

    constructor(private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.url = this.getChatUrl()
    }

    getChatUrl() {
        let url = Streaming.getChatUrl(this.video_id) + "&embed_domain=" + window.location.hostname

        return this.sanitizer.bypassSecurityTrustResourceUrl(url)
    }

    setRatioSize() {
        if (this.chatIframe) {
            if (this.chatIframe.nativeElement.offsetWidth > this.max_width) {
                this.width = this.max_width
            } else {
                this.width = this.chatIframe.nativeElement.offsetWidth
            }
        }
        //this.height = (this.width * this.max_height) / this.max_width; //Esto no funciona oe
    }

    onResize(event) {
        this.setRatioSize()
    }

    onLoadIframe() {
        this.cdr.detectChanges()
        this.setRatioSize()
    }
}
