import { Component, OnInit } from "@angular/core"
import { Usuarios } from "@puntaje/puntaje/api-services"

@Component({
    selector: "request-confirmation",
    templateUrl: "./request-confirmation.component.html",
    styleUrls: ["./request-confirmation.component.scss"]
})
export class RequestConfirmationComponent implements OnInit {
    instruccionesEnviadas = false

    constructor(public usuariosService: Usuarios) {}

    ngOnInit() {}

    requestConfirmarEmail() {
        this.usuariosService.requestConfirmarEmail().then(response => {
            this.instruccionesEnviadas = true
        })
    }
}
