import { Component, OnInit, ViewChild, ElementRef } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { LoadingLayoutComponent, CarouselSlide } from "@puntaje/shared/layouts"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"
import { LoginComponent } from "@puntaje/puntaje/new-modules/usuarios"

@Component({
    selector: "landing",
    templateUrl: "landing.component.html",
    styleUrls: ["landing.component.scss"]
})
export class LandingComponent implements OnInit {
    private totalHeight: number
    private windowHeight: number
    config: typeof config
    landingImageIndex: number = 0
    landingImage: string =
        "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/utem/landing/fondo_ensayo_paes_utem_2025.png"
    previousImage: string
    previousIndex: number = 0

    landingImages: string[] = [
        "https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/utem/landing/fondo_ensayo_paes_utem_2025.png"
    ]

    constructor(private router: Router, private route: ActivatedRoute, public authService: AuthService) {
        this.landingImageIndex = Math.floor(Math.random() * this.landingImages.length)
    }

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }
        this.resizeElements()
    }

    onResize(event) {
        this.resizeElements()
    }

    resizeElements() {
        this.windowHeight = window.innerHeight
        var cajas = document.querySelectorAll(".caja-inicio"),
            i
        ;(<HTMLElement>cajas[0]).style.minHeight = this.windowHeight + "px"
    }

    onLoadImage() {
        setTimeout(() => {
            this.previousIndex = this.landingImageIndex
            this.previousImage = "url(" + this.landingImages[this.previousIndex] + ")"
            setTimeout(() => {
                this.landingImageIndex = this.landingImageIndex =
                    (this.landingImageIndex + 1) % this.landingImages.length
            }, 15000)
        }, 4000)
    }
}
