<div class="previous-image" [style.background-image]="previousImage"></div>
<div
    class="absolute-background fading-image"
    [lazyLoad]="landingImages[landingImageIndex]"
    [offset]="200"
    (onLoad)="onLoadImage()"
></div>
<div class="content-container bottom-margin">
    <div class="row">
        <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-12">
            <div class="title-logo">
                <a routerLink="/home">
                    <img alt="logo" src="{{ config.app.assets.logoLargeLight }}" class="landing-logo" />
                </a>
            </div>
            <div class="shared-content-over-title" #sharedLayoutContentOverTitle>
                <l-slot name="content-over-title"></l-slot>
            </div>
            <div class="container-fluid content-subcontainer">
                <div class="offmargin">
                    <div class="custom_title_2">
                        <h1 class="ribbon">
                            <span class="ribbon-content">
                                <l-slot name="titulo"></l-slot>
                            </span>
                        </h1>
                    </div>
                </div>
                <div class="shared-content" [class.logged-layout-contenido]="showContenido" #sharedLayoutContenido>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>
