import { Component, Input, OnInit, OnDestroy } from "@angular/core"
import { EvaluacionInteractivaStatusService } from "./evaluacion_interactiva_status.service"
import { Subscription } from "rxjs"

@Component({
    selector: "evaluacion-interactiva-status",
    templateUrl: "evaluacion_interactiva_status.component.html",
    styleUrls: ["evaluacion_interactiva_status.component.scss"]
})
export class EvaluacionInteractivaStatusComponent implements OnInit, OnDestroy {
    correctasCounter: number = 0
    incorrectasCounter: number = 0
    pistasCounter: number = 0
    preguntasCounter: number = 0
    preguntasTotales: number = 20
    intentosCounter: number = 0
    sub: Subscription
    progressValueActual: number = 0

    constructor(protected evaluacionInteractivaStatusService: EvaluacionInteractivaStatusService) {}

    ngOnInit() {
        this.sub = this.evaluacionInteractivaStatusService.statusUpdated.subscribe((status: any) => {
            this.correctasCounter = status.correctas
            this.incorrectasCounter = status.incorrectas
            this.pistasCounter = status.pistas
            this.intentosCounter = status.intentos
            this.preguntasCounter = status.preguntaActual
            this.progressValueActual = (100 / this.preguntasTotales) * this.preguntasCounter
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
