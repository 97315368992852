import { Component, Input, Output, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "curso-search-filter",
    templateUrl: "curso_search_filter.component.html",
    styleUrls: ["curso_search_filter.component.scss"]
})
export class CursoSearchFilterComponent {
    currentSearch: string
    classSearch: string
    lastSearch: string = ""
    @Input() clasificaciones = {}
    @Output() classificationSearch = new EventEmitter()
    @Output() clearSearch = new EventEmitter()
    @Input() autoclearEnabled: boolean = true
    @Input() enableBarMode: boolean = true //El input de búsqueda flota a la derecha pero ocupa invisible todo el espacio a lo ancho
    @Input() placeholder: string = "Buscar por nombre..."
    @Input() elastic: boolean = false

    constructor() {}

    search() {
        this.lastSearch = this.currentSearch
        this.classificationSearch.emit({ currentSearch: this.currentSearch, classSearch: this.classSearch })
    }

    clear() {
        this.currentSearch = ""
        if (this.lastSearch.length > 0) {
            this.lastSearch = ""
            this.clearSearch.emit()
        }
    }

    autoclear() {
        if (this.currentSearch == "" && this.autoclearEnabled) {
            this.clearSearch.emit()
        }
    }
}
