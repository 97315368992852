<div class="showcasebox" [class.hollow-content]="!loadingLayout.loading">
    <div class="showcasebox_title">
        <div class="showcasebox_title_inner">
            <div class="showcasebox_title_icon">
                <div class="icon-container"><ogr-icon name="dialogos" class="icon"></ogr-icon></div>
            </div>
            <div class="showcasebox_title_text">
                <!-- Dudas recientes -->
                {{ "home.showcasebox.dudas_recientes.titulo" | t }}
            </div>
        </div>
    </div>
    <loading-layout #loadingLayout>
        <div class="lista-dudas" [class.showcasebox_content]="enableContentStyle">
            <duda-list-item-mini *ngFor="let duda of dudas" [duda]="duda"></duda-list-item-mini>
            <p class="not-found" *ngIf="dudas.length == 0">
                <!-- No se encontraron dudas recientes. -->
                {{ "home.showcasebox.dudas_recientes.no_dudas" | t }}
            </p>
        </div>
    </loading-layout>
    <div class="clearfix" [class.with-margin]="enableContentStyle">
        <a routerLink="/dudas" class="ver-mas pull-right">
            <!-- Ver más -->
            {{ "home.showcasebox.dudas_recientes.ver_mas" | t }}
        </a>
    </div>
</div>
