<div>
    <div class="categorias-container">
        <div class="categorias-row">
            <div
                class="categoria"
                *ngFor="let c of cursos; let i = index"
                [class.current]="categoria_id == c.categoria_id"
                (click)="onSelectCurso(c.categoria_id)"
                [ngClass]="setCursosClasses()"
            >
                <span class="long_name">{{ c.categoria }}</span>
                <span class="short_name" [innerHTML]="c.categoria_short"></span>
            </div>
        </div>
    </div>
</div>
