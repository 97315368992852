import { BaseForm, Validations, DateHelper } from "@puntaje/shared/core"
import { Validators } from "@angular/forms"
import { Lugares } from "./lugares.service"
import { Usuarios } from "./usuarios.service"
import { Clasificaciones } from "@puntaje/nebulosa/api-services"
import { Areas } from "@puntaje/carreras/api-services"

export class UsuarioEditPuntajeForm extends BaseForm {
    public static get formParams() {
        return {
            id: { label: "id", type: "hidden", visible: false },
            nombre: {
                label: "Nombre",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_paterno: {
                label: "Primer apellido",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            apellido_materno: {
                label: "Segundo apellido",
                type: "text",
                visible: true,
                validations: [Validators.required, Validators.minLength(2)]
            },
            fecha_nacimiento: {
                label: "Fecha de nacimiento",
                type: "date",
                visible: true,
                min: DateHelper.firstDayYear(150),
                max: DateHelper.today()
            },
            email: {
                label: "Correo electrónico",
                type: "text",
                visible: true,
                validations: [Validators.required, Validations.validateEmail],
                serviceValidators: [{ class: Usuarios, validator: Validations.validateUniqueEmailNotSelf }]
            },
            telefono: {
                label: "Celular",
                type: "text",
                visible: true,
                validations: [Validators.required]
            },
            lugar: {
                label: "Comuna",
                type: "autocomplete",
                visible: true,
                options: { class: Lugares, params: { lugar_tipo: "Comuna" } }
            },
            establecimiento_id: { label: "Establecimiento", type: "text", visible: true },
            nivel_id: {
                label: "Curso",
                type: "select",
                visible: true,
                options: { class: Clasificaciones, params: { clasificacion_tipo: { clasificacion_tipo: "curso" } } },
                validations: [Validators.required]
            },
            area_id: {
                label: "Área de interés",
                type: "select",
                visible: true,
                options: { class: Areas, params: {} }
            },
            created_at: { label: "Fecha de creación", type: "hidden", visible: true },
            updated_at: { label: "Fecha de actualización", type: "hidden", visible: true },
            asignatura_id: { label: "Asignatura", type: "hidden", visible: false },
            nem: {
                label: "Puntaje NEM",
                type: "number",
                visible: true,
                validations: [Validators.min(100), Validators.max(1000)]
            },
            ranking: {
                label: "Puntaje Ranking",
                type: "number",
                visible: true,
                validations: [Validators.min(100), Validators.max(1000)]
            },
            profesor: {
                label: "Soy...",
                type: "radio",
                visible: true,
                validations: [Validators.required],
                options: [
                    {
                        id: false,
                        toString: function () {
                            return "Estudiante"
                        }
                    },
                    {
                        id: true,
                        toString: function () {
                            return "Profesor/a"
                        }
                    }
                ]
            }
        }
    }
}
