/** @format */

import { ClearCodigoConfirmacionData } from "@puntaje/puntaje/store"
/** @format */

import { Component, OnInit, Input, ViewChild } from "@angular/core"
import { ModalSelectTipoEnvioComponent } from "../modales/modal-select-tipo-envio/modal-select-tipo-envio.component"
import { ActivatedRoute } from "@angular/router"
import { Router } from "@angular/router"
import { HttpClient } from "@angular/common/http"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Usuarios, Usuario } from "@puntaje/puntaje/api-services"
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { SessionService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, selectCodigoConfirmacionData } from "@puntaje/puntaje/store"

@Component({
    selector: "usuario-registrado",
    templateUrl: "./usuario-registrado.component.html",
    styleUrls: ["./usuario-registrado.component.scss"]
})
export class UsuarioRegistradoComponent implements OnInit {
    @ViewChild(ModalSelectTipoEnvioComponent, { static: true })
    modalSelectTipoEnvioComponent: ModalSelectTipoEnvioComponent
    @ViewChild("loadingLayout", { static: true })
    loadingLayout: LoadingLayoutComponent
    tipoEnvio: string
    codigo: string
    invalidLogin: boolean
    message: string
    user_id: number
    messageResponse: string
    max_n_intentos: boolean
    selectEnvioConfirmacion: boolean = false
    usuario: any
    telefono: string = ""
    enviadoDesde: string = ""
    codigoConfirmacionData$ = this.store.pipe(select(selectCodigoConfirmacionData))
    codigoConfirmacionData = {}

    constructor(
        protected route: ActivatedRoute,
        protected http: HttpClient,
        protected usuariosServices: Usuarios,
        protected sessionService: SessionService,
        protected router: Router,
        protected store: Store<State>
    ) {}

    ngOnInit() {
        this.usuario = this.sessionService.getUserData()

        this.loadingLayout.ready()
        this.route.queryParams.subscribe(queryParams => {
            this.tipoEnvio = queryParams["tipoEnvio"]
            this.enviadoDesde = queryParams["enviadoDesde"]
        })

        this.codigoConfirmacionData$.subscribe(data => {
            if (data == null) {
                this.router.navigate([""])
            }
            this.codigoConfirmacionData = data
        })
    }

    generarNuevoCodigo() {
        this.http
            .get(environment.endpoints.base + "/usuarios/" + this.codigoConfirmacionData["id"] + "/has_celular")
            .toPromise()
            .then((response: any) => {
                if (response) {
                    this.telefono = " "
                    this.modalSelectTipoEnvioComponent.open()
                } else {
                    let params = {
                        id: this.codigoConfirmacionData["id"],
                        usuario: {
                            enviado_desde: this.enviadoDesde,
                            tipo_envio: "mail"
                        }
                    }
                    this.enviarCodigoAgain(params)
                }
            })
    }

    validarTipoEnvio(event) {
        let params = {
            id: this.codigoConfirmacionData["id"],
            usuario: {
                enviado_desde: this.enviadoDesde
            }
        }
        if (event.tipo == "sms") {
            params["usuario"]["tipo_envio"] = "sms"
            params["usuario"]["telefono"] = event.telefono
        }
        if (event.tipo == "mail") {
            params["usuario"]["tipo_envio"] = "mail"
        }

        this.enviarCodigoAgain(params)
    }

    enviarCodigoAgain(params) {
        this.usuariosServices.enableIgnoreModel()
        this.usuariosServices.enableIgnoreCatch()

        this.usuariosServices
            .sendCodigo(params)
            .then(response => {
                this.usuariosServices.disableIgnoreModel()
                this.modalSelectTipoEnvioComponent.close()
                this.messageResponse = ""
                this.max_n_intentos = false
                this.codigo = ""
                this.tipoEnvio = response.tipo_envio
            })
            .catch(response => {})
    }

    enviarCodigo() {
        this.loadingLayout.standby()
        let params = {
            usuario: {
                user_id: (this.user_id = this.usuario.id),
                codigo: this.codigo,
                enviado_desde: this.enviadoDesde
            }
        }

        this.usuariosServices.enableIgnoreModel()
        this.usuariosServices.enableIgnoreCatch()

        this.usuariosServices
            .sendCodigoConfirmation(params)
            .then(response => {
                this.usuariosServices.disableIgnoreModel()
                this.loadingLayout.ready()

                if (this.enviadoDesde == "forgot_password") {
                    this.store.dispatch(new ClearCodigoConfirmacionData())
                    this.router.navigate(["/usuarios/passwords/reset/" + response["reset_password_token"]])
                } else {
                    this.sessionService.setConfirmedAt(response["confirmed_at"])
                    this.router.navigate(["/home"])
                }
            })
            .catch(response => {
                this.loadingLayout.ready()

                if (response.error.confirmation_done == false) {
                    if (response.error.user.n_intentos >= 5) {
                        this.max_n_intentos = true
                        this.messageResponse = "Usted ya supero el limite de intentos, vuelva a generar otro código"
                    } else {
                        this.messageResponse = "Código incorrecto intente nuevamente"
                    }
                }
            })
    }
}
