<div class="status-container">
    <div class="status-cell status-avance top-cell">
        <p>Pregunta</p>
        <p class="avance">{{ preguntasCounter }}/{{ preguntasTotales }}</p>
        <progressbar class="active progressbar" [value]="progressValueActual"></progressbar>
    </div>
    <div class="status-cell b-left status-correctas">
        <div class="status-info">
            <span class="icono correctas glyphicon glyphicon glyphicon-ok-circle"></span>
            <p class="value">{{ correctasCounter }}</p>
            <p class="detail">Correctas</p>
        </div>
    </div>
    <div class="status-cell status-incorrectas">
        <div class="status-info">
            <span class="icono incorrectas glyphicon glyphicon glyphicon-remove-circle"></span>
            <p class="value">{{ incorrectasCounter }}</p>
            <p class="detail">Incorrectas</p>
        </div>
    </div>
    <div class="status-cell b-right status-intentos">
        <div class="status-info">
            <fa name="repeat" class="icono intentos"></fa>
            <p class="value">{{ intentosCounter }}</p>
            <p class="detail">Intentos</p>
        </div>
    </div>
    <div class="status-cell top-cell status-pistas">
        <div class="status-info">
            <fa name="search" class="icono pistas"></fa>
            <p class="value">{{ pistasCounter }}</p>
            <p class="detail">Pistas</p>
        </div>
    </div>
</div>
