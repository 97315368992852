import { Component, Input } from "@angular/core"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { ActivatedRoute, Router } from "@angular/router"
import { Subscription, combineLatest } from "rxjs"
import { AppConfig, I18nService } from "@puntaje/shared/core"
declare const config: AppConfig
import { select, Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedAsignaturaWithId
} from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "plan-clases-de-alumnos.component.html"
})
export class PlanClasesDeAlumnosComponent {
    asignaturaId: number
    asignatura: Asignatura
    tipoInstrumento: string
    generadorInstrumentos: GeneradorInstrumento[]
    hideProgress: boolean = false

    pagina: string = "plan estudio"
    planes_route: string = "/plan_estudios"
    showTitleOverMenu: boolean = true
    evaluacionTipos = []
    planClaseRoute: string = "/plan_estudios"
    showAsignatura: boolean = true
    msjNoPlanes: string
    showPlanesCompartidosProfesor: boolean = true
    showPlanesRecomendados: boolean = true
    titulo: string
    tituloSimple: string
    planesDeClase: string
    ignoreNivelesRecomendados: boolean = false

    subQuery: Subscription
    subData: Subscription
    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected authService: AuthService,
        protected store: Store<State>,
        protected i18nService: I18nService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.plan_clases.plan_clases_de_alumnos")
        this.planesDeClase = this.i18nService.translate("estudiar.plan_estudios.recomendados.titulo")

        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
                this.getGeneradores()
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.pagina = data.pagina ?? this.pagina
            this.planes_route = data.planes_route ?? this.planes_route
            this.showTitleOverMenu = data.showTitleOverMenu ?? this.showTitleOverMenu
            this.evaluacionTipos = data.evaluacionTipos ?? this.evaluacionTipos
            this.planClaseRoute = data.planClaseRoute ?? this.planClaseRoute
            this.showAsignatura = data.showAsignatura ?? this.showAsignatura
            this.msjNoPlanes = data.msjNoPlanes ?? this.msjNoPlanes
            this.showPlanesCompartidosProfesor =
                data.showPlanesCompartidosProfesor ?? this.showPlanesCompartidosProfesor
            this.showPlanesRecomendados = data.showPlanesRecomendados ?? this.showPlanesRecomendados
            this.titulo = data.titulo ?? this.i18nService.translate("estudiar.plan_estudios.titulo")
            this.tituloSimple =
                data.tituloSimple ??
                this.titulo ??
                this.i18nService.translate("estudiar.plan_estudios.compartidos.titulo_simple")
            this.ignoreNivelesRecomendados = data.ignoreNivelesRecomendados ?? this.ignoreNivelesRecomendados
        })
    }

    async getGeneradores() {
        const params = {
            asignatura_plataforma: {
                asignatura_id: this.asignaturaId,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoInstrumento
            },
            include: "[tipo_instrumento]"
        }

        this.generadorInstrumentos = await this.generadorInstrumentosService.where(params)
    }

    onAsignaturaIdListener(value: any) {
        this.asignaturaId = value
        this.getGeneradores()
    }

    onReadyAsignatura(asignaturaId) {
        const params = { queryParams: { tipo_instrumento: this.tipoInstrumento, asignatura_id: asignaturaId } }
        this.router.navigate([this.planes_route], params)
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
    }
}
