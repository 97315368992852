import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from "@angular/core"
import { Subscription } from "rxjs"

@Component({
    selector: "materiales-dirigidos-menu",
    templateUrl: "materiales_dirigidos_menu.component.html",
    styleUrls: ["materiales_dirigidos_menu.component.scss"]
})
export class MaterialesDirigidosMenuComponent implements OnInit {
    @Output() onReady: EventEmitter<any> = new EventEmitter<any>()
    clasificaciones: string[]
    clasificacionesSelected: string[]
    @Input() enableProfesores: boolean = true
    @Input() enableAlumnos: boolean = true
    @Input() enableEjercitacion: boolean = true
    clasificacionSelected: number = -1
    listaClasificaciones: any = {
        todos: { label: "Todos", icono: "todos", clasificacion: [], enabled: true },
        profesores: { label: "Material para Profesores", icono: "docente", clasificacion: ["Docente"], enabled: true },
        alumnos: { label: "Material para Alumnos", icono: "libro2-o", clasificacion: ["Alumno"], enabled: true },
        ejercitacion: {
            label: "Material de Ejercitación",
            icono: "editar-prueba",
            clasificacion: ["Ejercitación"],
            enabled: true
        }
    }

    constructor() {}

    ngOnInit() {
        this.setListaCategorias()
        this.showAll()
    }

    setListaCategorias() {
        this.listaClasificaciones.alumnos.enabled = this.enableAlumnos
        this.listaClasificaciones.profesores.enabled = this.enableProfesores
        this.listaClasificaciones.ejercitacion.enabled = this.enableEjercitacion
        this.listaClasificaciones.todos.enabled = (this.enableAlumnos && this.enableProfesores) || (this.enableAlumnos && this.enableEjercitacion) || (this.enableProfesores && this.enableEjercitacion)

        if (this.enableProfesores) this.listaClasificaciones.todos.clasificacion.push("Docente")
        if (this.enableAlumnos) this.listaClasificaciones.todos.clasificacion.push("Alumno")
        if (this.enableEjercitacion) this.listaClasificaciones.todos.clasificacion.push("Ejercitación")
    }

    showAll() {
        this.clasificacionSelected = 0
        this.clasificacionesSelected = this.listaClasificaciones.todos.clasificacion
        this.onReady.emit(this.clasificacionesSelected)
    }

    show(clasificacion, i) {
        this.clasificacionSelected = i
        this.clasificacionesSelected = clasificacion
        this.onReady.emit(this.clasificacionesSelected)
    }
}
