import { Component, HostBinding, OnInit, ViewChild } from "@angular/core"
import { EntrenadorUsuarios, Respuestas, EntrenadorUsuario, Respuesta } from "@puntaje/puntaje/api-services"
import { AuthService, I18nService } from "@puntaje/shared/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "entrenador-showcasebox",
    templateUrl: "./entrenador_showcase_box.component.html",
    styleUrls: ["./entrenador_showcase_box.component.scss"]
})
export class EntrenadorShowcaseBoxComponent implements OnInit {
    entrenadorUsuario: EntrenadorUsuario
    cantidadPreguntasActual: number
    cantidadPreguntasAnterior: number
    progressValueActual: number = 0
    animatedProgressValueActual: number = 0
    progressValueBarRange: number[]
    encouragement: string = "¡Sigue así!"
    pendingConfig: boolean = false
    @HostBinding("class") innerClass = "showcasebox_style_1_primary"

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        protected entrenadorUsuariosService: EntrenadorUsuarios,
        protected respuestasService: Respuestas,
        protected authService: AuthService,
        private translatorService: I18nService
    ) {}

    ngOnInit() {
        let params = {
            include: "entrenador_meta",

            entrenador_usuario: {
                usuario_id: this.authService.getUserData().id
            }
        }

        let euPromise = this.entrenadorUsuariosService.where(params).then((entrenadorUsuarios: EntrenadorUsuario[]) => {
            this.entrenadorUsuario = entrenadorUsuarios[0]
            this.pendingConfig = !(this.entrenadorUsuario && this.entrenadorUsuario.entrenador_meta)
        })

        let now = new Date()
        let startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1)
        let endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 8)

        let startOfPreviousWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() - 7 + 1)
        let endOfPreviousWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 1)

        let respuestasParams = {
            per: 0,
            respuesta: {
                not: {
                    alternativa_id: [0, "null"]
                },
                gte: {
                    created_at: startOfWeek.toISOString()
                },
                lt: {
                    created_at: endOfWeek.toISOString()
                }
            },

            evaluacion_instancia: {
                usuario_id: this.authService.getUserData().id
            }
        }

        let respuestasWeekPromise = this.respuestasService
            .where(respuestasParams)
            .then((_: Respuesta[], total: number) => {
                this.cantidadPreguntasActual = total
            })

        respuestasParams.respuesta.gte.created_at = startOfPreviousWeek.toISOString()
        respuestasParams.respuesta.lt.created_at = endOfPreviousWeek.toISOString()

        let respuestasPreviousWeekPromise = this.respuestasService
            .where(respuestasParams)
            .then((_: Respuesta[], total: number) => {
                this.cantidadPreguntasAnterior = total
            })

        Promise.all([euPromise, respuestasWeekPromise, respuestasPreviousWeekPromise]).then(() => {
            this.setProgressActual()
            this.loadingLayout.ready()
        })
    }

    setProgressActual() {
        if (this.entrenadorUsuario && this.entrenadorUsuario.entrenador_meta) {
            this.progressValueBarRange = [0, this.entrenadorUsuario.entrenador_meta.numero_preguntas]
        }
        if (this.cantidadPreguntasActual && this.progressValueBarRange) {
            this.progressValueActual =
                (100 / (this.progressValueBarRange[1] - this.progressValueBarRange[0])) *
                (this.cantidadPreguntasActual - this.progressValueBarRange[0])
        }
        setTimeout(() => {
            this.animatedProgressValueActual = this.progressValueActual
        }, 200)
        this.setSpeechText()
    }

    setSpeechText() {
        if (this.progressValueActual == 0) {
            this.encouragement = this.translatorService.translate("entrenador.entrenador_showcasebox.comenzar")
        } else if (this.progressValueActual > 0 && this.progressValueActual < 75) {
            this.encouragement = this.translatorService.translate("entrenador.entrenador_showcasebox.sigue_asi")
        } else if (this.progressValueActual >= 75 && this.progressValueActual < 100) {
            this.encouragement = this.translatorService.translate("entrenador.entrenador_showcasebox.ya_casi")
        } else if (this.progressValueActual >= 100) {
            this.encouragement = this.translatorService.translate("entrenador.entrenador_showcasebox.buen_trabajo")
        }
    }
}
