<div class="row text-center" style="float: none" *ngIf="last_video">
    <div class="col-md-4">
        <table class="table table-striped">
            <tr>
                <td class="minwidth"><b>Título</b></td>
                <td>{{ title }}</td>
            </tr>
            <tr>
                <td class="minwidth"><b>Descripción</b></td>
                <td>{{ description }}</td>
            </tr>
        </table>
    </div>
    <div #iframeContainer class="col-md-8">
        <iframe [width]="width" [height]="height" [src]="video_url" frameborder="0"></iframe>
    </div>
</div>
<div class="text-center" *ngIf="!last_video">No existe material disponible.</div>
