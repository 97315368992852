import { Component, Input, OnInit, Optional, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { Streaming, Streamings } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    selector: "streaming-live",
    templateUrl: "streaming_live.component.html",
    styleUrls: ["streaming_live.component.scss"]
})
export class StreamingLiveComponent implements OnInit {
    config: typeof config
    @ViewChild("streamingLoading", { static: true }) streamingLoading: LoadingLayoutComponent
    @Input() tipoStreaming: string
    @Input() channelAliasType: string
    @Input() channelAliasKey: string
    @Input() streaming: Streaming
    canal: string
    videoId: string

    constructor(private streamingService: Streamings) {
        this.config = config
    }

    ngOnInit() {
        if (!this.streaming) {
            this.getStreaming()
        }
    }

    channelAvailable() {
        return this.streaming || this.getChannel()
    }

    getChannel(): any {
        if (this.channelAliasType && this.channelAliasKey) {
            switch (this.channelAliasType) {
                case "nivel":
                    return this.config.evaluaciones[this.tipoStreaming].streamingChannelAliasByNivel[
                        this.channelAliasKey
                    ]
                default:
                    return undefined
            }
        } else {
            return this.config.evaluaciones[this.tipoStreaming].streamingChannelAlias
        }
    }

    getStreaming() {
        if (this.tipoStreaming) {
            const stream = this.getChannel()
            if (stream) {
                let params = {
                    streaming: { channel_alias: stream },
                    render_options: {
                        include: {
                            material: null
                        }
                    }
                }
                this.streamingService.where(params).then((streamings: Streaming[]) => {
                    if (streamings.length > 0) {
                        this.streaming = streamings[0]
                        this.canal = this.streaming.channel_id
                        this.videoId = this.streaming.video_id
                    }
                    this.streamingLoading.ready()
                })
            } else {
                this.canal = stream.channel_id
                this.streamingLoading.ready()
            }
        } else {
            this.streamingLoading.ready()
        }
    }

    updateStreamingInfo(streamings: Streaming[]) {
        const stream = streamings.find(s => s.channel_alias === this.getChannel())

        if (stream) {
            this.videoId = stream.video_id
        }
    }
}
