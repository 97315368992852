import { Component, OnInit, OnDestroy, Optional, Input, ViewChild } from "@angular/core"
import { Subscription } from "rxjs"
import { Angulartics2 } from "angulartics2"
import { ActivatedRoute, Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { StreamingLiveComponent } from "@puntaje/puntaje/new-modules/streaming"
import { AlertasService } from "@puntaje/puntaje/new-modules/shared"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "streaming-alumnos.component.html"
})
export class StreamingAlumnosComponent implements OnInit, OnDestroy {
    config: typeof config
    tracking: boolean = false
    currentTab: number = 1
    tipoStreaming: string
    subQuery: Subscription
    subAlertas: Subscription
    subData: Subscription
    pagina: string = "streaming"
    channelAliasType: string
    channelAliasKey: string

    disableEnvivo: boolean = false
    showTabUltimaClase: boolean = true
    enableMaterialRelacionado: boolean = false
    enableAlertas: boolean = false

    @ViewChild("streamingLive") streamingLive: StreamingLiveComponent

    constructor(
        protected titleService: TitleService,
        @Optional() public angulartics2: Angulartics2,
        protected route: ActivatedRoute,
        protected alertasService: AlertasService
    ) {
        this.config = config
    }

    ngOnInit() {
        this.titleService.setTitle("titles.core.streaming.streaming_alumnos")
        this.subQuery = this.route.queryParams.subscribe(query => {
            this.tipoStreaming = query["tipo"] || config.plataforma.streamingDefault
            this.channelAliasType = query["channel_alias_type"] || null
            this.channelAliasKey = query["channel_alias_key"] || null
            this.disableEnvivo = this.config.plataforma.streamingDisableEnVivo?.includes(this.tipoStreaming)
        })

        this.subData = this.route.data.subscribe(data => {
            this.showTabUltimaClase = data.showTabUltimaClase ?? this.showTabUltimaClase

            if (data.enableAlertas) {
                this.subAlertas = this.alertasService.streamingSubject.subscribe(streaming => {
                    this.streamingLive.updateStreamingInfo(this.alertasService.streamings)
                })
            }
        })
    }

    ngOnDestroy() {
        this.subQuery.unsubscribe()
        this.subData.unsubscribe()
        if (this.subAlertas) {
            this.subAlertas.unsubscribe()
        }
    }

    tabChanged = (id: number, tabName: string): void => {
        this.trackClickEvent(tabName)
        this.currentTab = id
    }

    trackClickEvent(tabName: string) {
        if (this.tracking && this.angulartics2) {
            this.angulartics2.eventTrack.next({
                action: "click",
                properties: {
                    category: tabName,
                    label: "envivo"
                }
            })
        }
    }
}
