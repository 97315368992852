<div class="contenedor" data-spy="scroll" data-target=".navbar-puntaje" (window:resize)="resizeElements()">
    <div class="mascara">
        <!-- Login -->
        <div id="section1">
            <div class="caja caja-inicio">
                <div class="previous-image" [style.background-image]="previousImage"></div>
                <div
                    class="fading-image"
                    [lazyLoad]="landingImages[landingImageIndex]"
                    [offset]="200"
                    (onLoad)="onLoadImage()"
                ></div>
                <div class="caja-inner-margin">
                    <div class="content-container">
                        <div class="inner-padding">
                            <img
                                class="landing-logo"
                                src="{{ config.app.assets.logoMedium }}"
                                alt="{{ config.plataforma.info.companyName }}"
                            />
                        </div>
                        <hr />
                        <div class="inner-padding">
                            <login-utem
                                [enableContactUs]="false"
                                [enableRegister]="false"
                                [enableFacebookLogin]="false"
                                [enablePasswordRecovery]="true"
                            ></login-utem>
                            <p class="contacto">
                                Si no puedes ingresar, escríbenos a <a href="mailto:contactoutem@ogr.cl">contactoutem@ogr.cl</a>
                            </p>
                        </div>
                        <div class="deco">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <!-- <img
                        class="cna"
                        src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/utem/landing/cna_light_2.png"
                        alt=""
                    /> -->
                    <div class="cna"></div>
                </div>
            </div>
        </div>
    </div>
    <simple-modal></simple-modal>
</div>
