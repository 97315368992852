<loading-layout #loadingLayout>
    <div *ngIf="clasificacionesRearranged">
        <div class="{{ asignatura.codigo_estilo }}">
            <cui-section-headline>
                {{
                    "biblioteca.subtitulo"
                        | t
                            : {
                                  clasificacion:
                                      config.evaluaciones[config.plataforma.evaluacionDefault].clasificacionMaterial
                                          .label
                              }
                }}
            </cui-section-headline>
            <div *ngFor="let clasificacion of clasificacionesRearranged; let i = index">
                <div class="container_level_1">
                    <div class="title_level_1" [toggleExpandable]="contenidoNivel1">
                        <div class="t-body">
                            <div class="t-cell">
                                <h2>{{ clasificacion.clasificacion }}</h2>
                            </div>
                            <div class="t-cell title_level_1_count">
                                <span>
                                    <ogr-icon name="libro2-o" align="middle"></ogr-icon>
                                    &nbsp;({{ contadorMateriales[i].total }})
                                </span>
                            </div>
                            <div class="t-cell title_level_1_icon">
                                <span class="glyphicon glyphicon-minus-sign expanded-sign"></span>
                                <span class="glyphicon glyphicon-plus-sign contracted-sign"></span>
                            </div>
                        </div>
                    </div>
                    <div [expandableContent] class="wrapper_level_1" #contenidoNivel1>
                        <div class="inner-margins_cont_level_1">
                            <div class="cont_level_1">
                                <table
                                    class="table tabla-materiales"
                                    *ngIf="clasificacion.materiales_propios.length > 0"
                                >
                                    <tr
                                        *ngFor="let m of clasificacion.materiales_propios; let i = index"
                                        [class.odd]="i % 2 == 1"
                                    >
                                        <td class="file-icon-cell icono">
                                            <div class="file-icon">
                                                <tipo-material-icon
                                                    [tipo]="$any(m.material_tipo_id)"
                                                ></tipo-material-icon>
                                            </div>
                                        </td>
                                        <td class="nombre">
                                            <a (click)="openMaterialPreview(m)">{{ m }}</a>
                                            <p class="clasificaciones">
                                                <span class="clasificacion" *ngFor="let c of m.clasificaciones">
                                                    {{ c }}
                                                </span>
                                            </p>
                                        </td>
                                        <td class="acciones">
                                            <cui-button-link
                                                class="btn_style btn-small margin_right"
                                                (click)="openMaterialPreview(m)"
                                            >
                                                Previzualizar
                                            </cui-button-link>
                                            <cui-button-link
                                                [routerLinkValue]="'/materiales/' + m.id"
                                                class="btn_style btn-small"
                                            >
                                                <fa name="search"></fa>
                                                &nbsp;Abrir
                                            </cui-button-link>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p
                *ngIf="
                    !clasificacionesRearranged || (clasificacionesRearranged && clasificacionesRearranged.length == 0)
                "
                class="not-found"
            >
                Sin material disponible para esta categoría.
            </p>
        </div>
    </div>
    <material-preview-modal #materialPreviewModal></material-preview-modal>
</loading-layout>
