import { Component, OnInit } from "@angular/core"
import { TitleService } from "@puntaje/shared/core"

@Component({
    templateUrl: "./entrenador_usuarios_edit.component.html"
})
export class EntrenadorUsuariosEditComponent implements OnInit {
    constructor(protected titleService: TitleService) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.entrenador.entrenador_usuarios.edit")
    }
}
