import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"

@Component({
    selector: "app-home-planes-personales",
    templateUrl: "./home-planes-personales.component.html",
    styleUrls: ["./home-planes-personales.component.scss"]
})
export class HomePlanesPersonalesComponent implements OnInit {
    menuItems = [
        {
            route: "/mis_planes_personales/",
            params: {},
            label: "Planes Personales",
            text: "Planes Personales.",
            linkText: "Planes Personales",
            icon: "hacer-prueba"
        },
        {
            route: "/planes_compartidos/",
            params: {},
            label: "Planes Compartidos",
            text: "Planes Compartidos.",
            linkText: "Planes Compartidos",
            icon: "hacer-prueba"
        }
    ]

    constructor(protected loggedLayoutService: LoggedLayoutService) {}

    ngOnInit() {
        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }
}
