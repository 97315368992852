import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Input, Injector } from "@angular/core"
import { Subscription } from "rxjs"
import { FormBuilder, UntypedFormGroup } from "@angular/forms"
import { Router } from "@angular/router"
import {
    Usuario,
    Usuarios,
    UsuarioEdit,
    UsuarioEditForm,
    UsuarioAvatarEdit,
    UsuarioAvatarEditForm,
    Establecimiento,
    Establecimientos,
    Lugar,
    Lugares
} from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { S3, S3Service, AuthService, AppEnv } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { Clasificacion, Clasificaciones } from "@puntaje/nebulosa/api-services"
import {
    UsuarioEditPuntaje,
    UsuarioEditPuntajeForm,
    UsuarioEditInfoPersonalPuntajeForm,
    UsuarioEditInfoAcademicaPuntajeForm
} from "@puntaje/puntaje/api-services"
import {
    PreferenciaUniversidad,
    PreferenciaUniversidades,
    PreferenciaArea,
    PreferenciaAreas
} from "@puntaje/carreras/api-services"
import { CheckAchievement, CheckAchievementAsync, CheckActividadAsync } from "@puntaje/shared/core"
import { Store } from "@ngrx/store"
import { State, CrearAlertaLogros } from "@puntaje/puntaje/store"
import { UsuarioEmailModalComponent } from "./usuario-email-modal/usuario-email-modal.component"

@Component({
    selector: "usuarios-puntaje-edit",
    templateUrl: "usuarios_puntaje.edit.component.html",
    styleUrls: ["usuarios.edit.component.scss"]
})
export class UsuariosPuntajeEditComponent implements OnInit {
    usuario: Usuario
    usuarioEdit: UsuarioEditPuntaje
    usuarioAvatarEdit: UsuarioAvatarEdit
    oUsuarioEdit: UsuarioEditPuntaje
    oUsuarioAvatarEdit: UsuarioAvatarEdit
    pais: string = config.plataforma.pais
    idPais: string[] = config.plataforma.identificadorUsuario
    idPaisAliases: any = {}
    plataforma: string
    curso: string

    @Input() enableShowCountryData: boolean = false

    paramsAvatar = UsuarioAvatarEditForm.formParams
    params = UsuarioEditPuntajeForm.formParams

    @Input() usuarioId: number

    formAvatar: UntypedFormGroup
    formInfoPersonal: UntypedFormGroup
    formInfoAcademica: UntypedFormGroup

    filteredEstablecimientos: Establecimiento[]

    preferenciaUniversidadesAmount: number = 3
    preferenciaAreasAmount: number = 1

    edit_user_info: boolean = false
    edit_academic_info: boolean = false
    edit_avatar: boolean = false
    edit_password: boolean = false
    private sub: Subscription
    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent
    @ViewChild("loadingLayoutAvatar") loadingLayoutAvatar: LoadingLayoutComponent
    @ViewChild(UsuarioEmailModalComponent, { static: true }) usuarioEmailModalComponent: UsuarioEmailModalComponent
    loadingAvatar: boolean = false
    lugarLabel: string
    usoDatos: boolean = false

    lugarColegio: Lugar
    filteredLugarColegio: Lugar[]

    @Input() enableTutor: boolean = false

    msjNem =
        "Puntaje que se calcula a partir de tu promedio de notas de enseñanza media, mediante tablas de conversión." +
        "Para calcular tu NEM, ingresa acá: " +
        "<a href='https://demre.cl/proceso-admision/factores-seleccion/tabla-transformacion-nem-p2023'>Tabla conversión NEM</a>"

    msjRanking =
        "El Ranking de Notas por contexto educativo se calcula como una bonificación al puntaje NEM cuando " +
        "el estudiante obtiene un promedio de notas superior al promedio histórico del contexto educativo cursado. " +
        "Para calcular tu Ranking, ingresa al link: " +
        "<a href='https://simulador.demre.cl/'>Simulador DEMRE</a>. " +
        "Para más información: <a href='https://psu.demre.cl/proceso-admision/factores-seleccion/puntaje-ranking'>Puntaje Ranking DEMRE</a>. " +
        "Si no sabes como calcular tu ranking, puedes colocar un puntaje igual a tu NEM."

    hasCarreras: boolean = !!this.environment.endpoints.carreras
    withIPCFT = config.plataforma.withIPCFT

    saving = false

    constructor(
        protected authService: AuthService,
        protected usuariosService: Usuarios,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        public s3Service: S3Service,
        protected injector: Injector,
        protected establecimientosService: Establecimientos,
        protected clasificacionesService: Clasificaciones,
        protected preferenciaUniversidadesService: PreferenciaUniversidades,
        protected preferenciaAreasService: PreferenciaAreas,
        protected lugaresService: Lugares,
        protected store: Store<State>,
        protected environment: AppEnv
    ) {
        this.lugarLabel = config.plataforma.lugarLabel ? config.plataforma.lugarLabel : null
        this.idPais.forEach(id => {
            this.idPaisAliases[id] = config.plataforma?.identificadorUsuarioAlias?.[id] || id
        })

        UsuarioAvatarEditForm.injector = injector
        UsuarioEditPuntajeForm.injector = injector
    }

    ngOnInit() {
        this.plataforma = config.plataforma.name
        this.usuariosService
            .find(this.usuarioId, {
                include: "usuario_" + this.pais
            })
            .then((usuario: Usuario) => {
                this.usuario = usuario
                this.usuarioEdit = new UsuarioEditPuntaje(usuario.id)
                this.usuarioEdit.fromUsuario(usuario)

                this.formInfoPersonal = UsuarioEditInfoPersonalPuntajeForm.getForm(
                    this.usuarioEdit,
                    null,
                    this.injector
                )
                this.formInfoAcademica = UsuarioEditInfoAcademicaPuntajeForm.getForm(
                    this.usuarioEdit,
                    null,
                    this.injector
                )

                if (this.usuario.establecimiento_id) {
                    this.establecimientosService.enableIgnoreCatch()
                    this.establecimientosService
                        .find(this.usuario.establecimiento_id)
                        .then((establecimientos: Establecimiento) => {
                            this.establecimientosService.disableIgnoreCatch()
                            this.usuario.establecimiento = establecimientos
                        })
                        .finally(() => {
                            this.establecimientosService.disableIgnoreCatch()
                        })
                } else {
                    this.usuario.establecimiento = null
                }

                this.clasificacionesService
                    .where({ clasificacion: { id: this.usuario.nivel_id } })
                    .then((clasificaciones: Clasificacion[]) => {
                        if (clasificaciones.length > 0) this.curso = clasificaciones[0].clasificacion
                    })
                this.usuarioAvatarEdit = new UsuarioAvatarEdit(usuario.id)
                this.usuarioAvatarEdit.fromUsuario(usuario)
                this.oUsuarioAvatarEdit = this.usuarioAvatarEdit.clone()
                this.formAvatar = UsuarioAvatarEditForm.getForm(this.usuarioAvatarEdit)

                if (this.hasCarreras) {
                    this.preferenciaUniversidadesService
                        .where({
                            preferenciaUniversidad: { usuarioId: this.usuarioId },
                            include: "universidad"
                        })
                        .then((preferenciaUniversidades: PreferenciaUniversidad[]) => {
                            this.usuarioEdit.preferenciaUniversidades = preferenciaUniversidades.slice(
                                0,
                                this.preferenciaUniversidadesAmount
                            )

                            this.preferenciaAreasService
                                .where({ preferenciaArea: { usuarioId: this.usuarioId }, include: "area" })
                                .then((preferenciaAreas: PreferenciaArea[]) => {
                                    this.usuarioEdit.preferenciaAreas = preferenciaAreas
                                    if (this.usuarioEdit.preferenciaAreas.length == 0) {
                                        this.usuarioEdit.preferenciaAreas = [new PreferenciaArea()]
                                    }
                                    this.oUsuarioEdit = this.usuarioEdit.clone()
                                    this.loadingLayout.ready()
                                    this.cdr.detectChanges()
                                })
                        })
                } else {
                    this.oUsuarioEdit = this.usuarioEdit.clone()
                    this.loadingLayout.ready()
                    this.cdr.detectChanges()
                }
            })
    }

    saveProfileImage() {
        UsuarioAvatarEditForm.markFormControlsAsTouched(this.formAvatar)
        if (this.formAvatar.valid) {
            this.loadingLayoutAvatar.standby()
            this.s3Service.where(this.usuarioAvatarEdit.getS3Params()).then((policies: S3[]) => {
                let policy = policies as any as S3
                this.usuarioAvatarEdit["avatar"] = policy["key"]
                this.s3Service.uploadToS3(
                    policy,
                    this.usuarioAvatarEdit.file,
                    this.usuariosService.tableName,
                    this.updateUsuario.bind(this)
                )
            })
        }
    }

    @CheckAchievementAsync(["PN_SUBIR_AVATAR", "PN_COMPLETAR_PERFIL"], CrearAlertaLogros)
    @CheckActividadAsync("SA")
    updateUsuario() {
        return this.usuariosService
            .update(this.usuarioAvatarEdit.usuario_id, this.usuarioAvatarEdit.toUsuario())
            .then((response: Usuario) => {
                this.authService.setUserData(response)
                this.usuario = response
                setTimeout(() => {
                    this.usuarioAvatarEdit["file"] = undefined
                    this.usuarioEdit["avatar"] = this.usuario.avatar
                    UsuarioEditForm.markFormControlsAsPristine(this.formAvatar)
                    UsuarioEditForm.markFormControlsAsUntouched(this.formAvatar)
                    this.cdr.detectChanges()
                    this.loadingAvatar = true
                }, 150)

                return response
            })
    }

    getEstablecimientos(establecimientoFilter: string) {
        this.establecimientosService
            .where({
                per: 100,
                raw: 1,
                establecimiento: {
                    establecimiento: establecimientoFilter,
                    ...(this.lugarColegio && { lugar_id: this.lugarColegio.id })
                },
                lugar_tipo: { lugar_tipo: ["Ciudad", "Región", "Provincia", "Comuna"] }
            })
            .then((establecimientos: Establecimiento[]) => {
                this.filteredEstablecimientos = establecimientos
            })
    }

    updateColegio(establecimiento: Establecimiento) {
        this.usuarioEdit.establecimiento_id = establecimiento.id
    }

    updatePreferenciasUniversidades(preferencias) {
        this.usuarioEdit.preferenciaUniversidades = preferencias
    }

    updatePreferenciaArea(event) {
        let preferenciaArea
        if (!this.usuarioEdit.preferenciaAreas) {
            preferenciaArea = new PreferenciaArea()
            preferenciaArea.usuarioId = this.usuarioEdit.id
            this.usuarioEdit.preferenciaAreas.push(preferenciaArea)
        } else {
            preferenciaArea = this.usuarioEdit.preferenciaAreas[0]
        }

        preferenciaArea.areaId = event.target.value || preferenciaArea.areaId
        preferenciaArea._destroy = !event.target.value
    }

    trackByPreferenciaArea(index: number, preferenciaArea: PreferenciaArea) {
        return preferenciaArea.id
    }

    onLoadProfileImage() {
        if (this.loadingAvatar) {
            this.loadingLayoutAvatar.ready()
            this.loadingAvatar = false
        }
    }

    @CheckAchievementAsync("PN_COMPLETAR_PERFIL", CrearAlertaLogros)
    save(form: UntypedFormGroup) {
        UsuarioEditForm.markFormControlsAsTouched(form)
        if (form.valid) {
            this.loadingLayout.standby()
            this.saving = true
            return this.usuariosService.update(this.usuarioEdit.id, this.usuarioEdit.toUsuario()).then(response => {
                this.saving = false
                if (form.controls["email"] && !form.controls["email"].pristine) {
                    this.usuarioEmailModalComponent.open()
                }
                this.authService.setUserData(response)
                this.router.navigate(["usuarios/" + this.usuarioEdit.id])
                this.loadingLayout.ready()
                return response
            })
        }
        return null
    }

    clear(form: UntypedFormGroup) {
        setTimeout(() => {
            this.usuarioEdit = this.oUsuarioEdit.clone()
            UsuarioEditForm.markFormControlsAsPristine(form)
            UsuarioEditForm.markFormControlsAsUntouched(form)

            this.cdr.detectChanges()
        }, 150)
    }

    toggleUserInfo(event) {
        event.stopPropagation()
        this.edit_user_info = !this.edit_user_info
    }

    toggleAcademicInfo(event) {
        event.stopPropagation()
        this.edit_academic_info = !this.edit_academic_info
    }

    toggleAvatarEdit(event) {
        ;(document.getElementById("avatarInput").getElementsByClassName("inputfile-label")[0] as HTMLElement).click()
        event.stopPropagation()
    }

    cancelNewAvatar() {
        this.usuarioAvatarEdit["file"] = undefined
    }

    showEditPassword() {
        this.edit_password ? (this.edit_password = false) : (this.edit_password = true)
    }

    /**
     * Activa/desactiva la restriccion de compartir los datos con terceros.
     */
    activarRestriccionDatos() {
        const oldValue = this.usuario.restriccion_datos
        this.usuario.restriccion_datos = !oldValue
        this.usuariosService
            .activarRestriccionDatos(this.usuario.id, this.usuario.restriccion_datos)
            .then((response: any) => {
                if (!response.success) {
                    this.usuario.restriccion_datos = oldValue
                }
            })
    }

    updateLugarColegio(lugar: any) {
        if (lugar instanceof Lugar) {
            this.lugarColegio = lugar
        }
    }

    getLugarColegio(lugarFilter: string) {
        this.lugaresService
            .where({ per: 100, raw: 1, filter: lugarFilter, lugar_tipo: ["Comuna"] })
            .then((lugares: Lugar[]) => {
                this.filteredLugarColegio = lugares
            })
    }
}
