import { Component, Directive, ViewChild } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Template simple para páginas para usuario sin tipo de usuario en particular.
Implementa una sección de contenido y un título.

shared-layout-titulo
shared-layout-contenido

#################################*/

@Component({
    templateUrl: "shared_layout.component.html",
    selector: "shared-layout",
    styleUrls: ["shared_layout.component.scss"]
})
export class SharedLayoutComponent {
    config: typeof config = config
    @ViewChild("sharedLayoutContenido", { static: true }) sharedLayoutContenido
    @ViewChild("sharedLayoutContentOverTitle", { static: true }) sharedLayoutContentOverTitle
    showContenido: boolean

    landingImageIndex: number = 0
    landingImage: string = "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/utem/landing/landing_1.jpg"
    previousImage: string
    previousIndex: number = 0

    landingImages: string[] = [
        "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/utem/landing/landing_0.jpg",
        "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/utem/landing/landing_1.jpg",
        "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/utem/landing/landing_2.jpg",
        "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/utem/landing/landing_3.jpg",
        "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/utem/landing/landing_4.jpg"
    ]

    constructor() {
        this.landingImageIndex = Math.floor(Math.random() * this.landingImages.length)
    }

    onLoadImage() {
        setTimeout(() => {
            this.previousIndex = this.landingImageIndex
            this.previousImage = "url(" + this.landingImages[this.previousIndex] + ")"
            setTimeout(() => {
                this.landingImageIndex = this.landingImageIndex =
                    (this.landingImageIndex + 1) % this.landingImages.length
            }, 15000)
        }, 4000)
    }
}
