<footer title="Informacion de la empresa">
    <div class="txt-company-footer">
        <div class="leftColumn">
            <p class="siguenos">Síguenos en</p>
            <div class="socialMedia">
                <div class="t_row">
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.facebook }}" target="_blank" title="Facebook">
                            <fa name="facebook"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.instagram }}" target="_blank" title="Instagram">
                            <fa name="instagram"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.linkedin }}" target="_blank" title="Linkedin">
                            <fa name="linkedin"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.twitter }}" target="_blank" title="Twitter">
                            <fa name="twitter"></fa>
                        </a>
                    </div>
                    <div class="t_cell">
                        <a href="{{ config.plataforma.info.social.youtube }}" target="_blank" title="Youtube">
                            <fa name="youtube"></fa>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="contacto">
            <p>Si tienes consultas o comentarios, escríbenos a 
                <a href="mailto:contactoutem@ogr.cl">contactoutem@ogr.cl</a>
            </p>
        </div>   

        <div class="rightColumn">
            <img
                class="cna"
                src="https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/utem/landing/cna_light.png"
                alt=""
            />
        </div>
    </div>
</footer>
