<loading-layout #loadingLayout>
    <ng-container>
        <div *ngIf="tipoEnvio == 'sms'">
            Hemos enviado un mensaje de texto con el código de verificación. Si no llega en 5 minutos o más, por favor
            envía un nuevo código.
        </div>

        <div *ngIf="tipoEnvio == 'mail'">
            Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no llega en 5
            minutos o más, por favor revisa tu carpeta de spam.
        </div>
        <div class="contentCodigo">
            <label *ngIf="enviadoDesde == 'forgot_password'">
                Ingresa el Código de confirmación para cambiar tu contraseña
            </label>
            <label *ngIf="enviadoDesde != 'forgot_password'">
                Ingresa el Código de confirmación para activar tu cuenta
            </label>
            <input type="text" class="form-control" [(ngModel)]="codigo" />
            <div>
                <a class="pull-right" (click)="generarNuevoCodigo()">Enviar un Nuevo Código</a>
                <div *ngIf="messageResponse" class="error">
                    {{ messageResponse }}
                </div>
            </div>
            <div class="clearfix"></div>
            <button class="btn btn-default pull-right" [disabled]="max_n_intentos" (click)="enviarCodigo()">
                Enviar
            </button>
        </div>
    </ng-container>
</loading-layout>

<modal-select-tipo-envio (tipo)="validarTipoEnvio($event)" [telefono]="telefono"></modal-select-tipo-envio>
