import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        carreras: urls.carrerasUrl,
        achievements: urls.achievementsUrl
    },
    googleAnalyticsPropertyId: {},
    // globalSiteTag: {
    //     alumnos: { propertyId: "G-57ZGT1R0B5", sendTo: "" },
    // },
    googleTagManagerPropertyId: {
        // profesores: "GTM-PKWTN65",
        alumnos: "GTM-PSMHCMS"
    },
    facebookAnalyticsPropertyId: {},
    domain: {
        profesores: "docentes.puntajenacional.cl",
        alumnos: "www.ensayoutem.cl",
        admin: "admin.ensayosutem.ogr.cl"
    },
    facebook: {
        appId: "1104415359622082",
        apiVersion: "v2.11"
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    achievementsUrl: urls.achievementsUrl,
    facebookAppId: "1104415359622082",
    facebookApiVersion: "v2.6",
    domain: {
        profesores: "docentes.puntajenacional.cl",
        alumnos: "www.puntajenacional.cl",
        admin: "admin.puntajenacional.cl"
    }
}
