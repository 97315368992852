import { PuntajeEntrenadorModule } from "@puntaje/puntaje/core"
import { NgModule, ModuleWithProviders } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CommonModule } from "@angular/common"

import { routing } from "./alumnos_entrenador.routing"
import { EntrenadorModule } from "@puntaje/puntaje/new-modules/entrenador"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

@NgModule({
    imports: [CommonModule, AlumnosLayoutsModule, EntrenadorModule, routing, PuntajeEntrenadorModule],
    declarations: [],
    exports: []
})
export class AlumnosEntrenadorModule {}
