<ll-titulo>Material SIMCE</ll-titulo>
<ll-subtitulo>{{ asignatura?.asignatura }}</ll-subtitulo>

<banner-materiales-simce></banner-materiales-simce>
<materiales-cursos (onReady)="onReadyCurso($event)" [tipoCursos]="'simce'"></materiales-cursos>
<div (window:resize)="onResize($event)" [class.enableResponsive]="enableResponsive">
    <div class="left_tabs">
        <menu-asignaturas-vertical
            [asignaturaId]="asignaturaId"
            [enableResponsive]="enableResponsive"
            [tipoAsignaturas]="'simce'"
        ></menu-asignaturas-vertical>
    </div>
    <div class="tabs_content">
        <materiales
            [asignaturaId]="asignaturaId"
            [cursoId]="cursoId"
            [clasificacionEspecial]="'simce'"
            (onReady)="onReady($event)"
        ></materiales>
    </div>
</div>
