import { Injectable, TemplateRef } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { LayoutService } from "../layout.service"

interface RestrictedLayoutOptions {
    freeContentStyle?: boolean
}

@Injectable({ providedIn: "root" })
export class RestrictedLayoutService extends LayoutService<RestrictedLayoutOptions> {
    defaultOptions: RestrictedLayoutOptions = {
        freeContentStyle: false
    }

    options$ = new BehaviorSubject<RestrictedLayoutOptions>(this.defaultOptions)
}
